import { Ionicons } from '@expo/vector-icons';
import * as WebBrowser from 'expo-web-browser';
import * as React from 'react';
import { StyleSheet, Text, View, Alert, Platform } from 'react-native';
import { RectButton, ScrollView, TouchableOpacity } from 'react-native-gesture-handler';
import { LoggContext, UserContext } from '../stores/Store.tsx';
import Constants from 'expo-constants';
import * as Updates from 'expo-updates';
import PreLoader from '../components/PreLoader';
import { checkDiskAvailable } from '../utils/validation';
import config from '../utils/config';
import { loggedValues } from '../utils/enums';

export default function SettingsScreen() {
  const [, setLogged] = React.useContext(LoggContext);
  const [, setUser] = React.useContext(UserContext);
  const LogOut = () => {
    setUser(null);
    setLogged(false);
  };
  const [working, setWorking] = React.useState(false);

  const reloadIfUpdateAvailable = async () => {
    try {
      setWorking(true);
      const update = await Updates.checkForUpdateAsync();
      if (update.isAvailable) {
        Alert.alert(
          'Actualización',
          'Detectamos una actualizacion disponible para la aplicación',
          [
            {
              text: 'Actualizar',
              onPress: async () => {
                Alert.alert('', 'La app se reinciará automaticamente, por favor espera');
                await Updates.fetchUpdateAsync()
                  .then(() => {
                    setWorking(false);
                    Updates.reloadAsync();
                  });
              },
            },
          ],
          { cancelable: false },
        );
      } else {
        Alert.alert(
          'Actualización',
          'Estas usando la ultima versión disponible',
          [
            {
              text: 'OK',
              onPress: () => {
                setWorking(false);
              },
            }
          ],
          { cancelable: false },
        );
      }
    } catch (e) {
      console.log(e)
      Alert.alert('Ha ocurrido un error al buscar actualizaciones', `${e}`)
      setWorking(false);
    }
  };

  const RenderSettingsWeb = () => (
    <ScrollView style={styles.container} contentContainerStyle={styles.contentContainer}>
      <OptionButton
        icon="md-information-circle"
        label={`Version: ${Constants.manifest.version}`}
        onPress={() => null}
        onLongPress={() => alert(`${__DEV__ ? `Host ${config.api.host}` : ''}
        Channel ${Constants.manifest.releaseChannel}
        `)}
      />

      <OptionButton
        icon="md-log-out"
        label="Cerrar sesión"
        onPress={() => LogOut()}
        isLastOption
      />
    </ScrollView>
  );

  const RenderSettingsMobile = () => (
    <ScrollView style={styles.container} contentContainerStyle={styles.contentContainer}>
      <OptionButton
        icon="md-information-circle"
        label={`Version: ${Constants.manifest.version}`}
        onPress={() => null}
        onLongPress={() => Alert.alert('Info', `${__DEV__ ? `Host ${config.api.host}` : ''}
        Channel ${Constants.manifest.releaseChannel}
        `)}
      />

      <OptionButton
        icon="md-code-download"
        label="Buscar actualización"
        onPress={() => reloadIfUpdateAvailable()}
      />

      <OptionButton
        icon="md-disc"
        label="Ver espacio disponible"
        onPress={() => {
          checkDiskAvailable().then((info) => {
            console.log(info);
            Alert.alert('Espacio disponible: ', info);
          })
        }}
      />

      <OptionButton
        icon="md-log-out"
        label="Cerrar sesión"
        onPress={() => LogOut()}
        isLastOption
      />
    </ScrollView>
  );

  if (working) {
    return <PreLoader />
  }

  if(Platform.OS == 'web'){
    return <RenderSettingsWeb />
  }

  return <RenderSettingsMobile />

}

function OptionButton({ icon, label, onPress, isLastOption, onLongPress }) {
  return (
    <TouchableOpacity style={[styles.option, isLastOption && styles.lastOption]} onLongPress={onLongPress} onPress={onPress} >
      <View style={{ flexDirection: 'row' }}>
        <View style={styles.optionIconContainer}>
          <Ionicons name={icon} size={22} color="rgba(0,0,0,0.35)" />
        </View>
        <View style={styles.optionTextContainer}>
          <Text style={styles.optionText}>{label}</Text>
        </View>
      </View>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fafafa',
  },
  contentContainer: {
    paddingTop: 15,
  },
  optionIconContainer: {
    marginRight: 12,
  },
  option: {
    backgroundColor: '#fdfdfd',
    paddingHorizontal: 15,
    paddingVertical: 15,
    borderWidth: StyleSheet.hairlineWidth,
    borderBottomWidth: 0,
    borderColor: '#ededed',
  },
  lastOption: {
    borderBottomWidth: StyleSheet.hairlineWidth,
  },
  optionText: {
    fontSize: 15,
    alignSelf: 'flex-start',
    marginTop: 1,
  },
});
