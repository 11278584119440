const expencesKind = [
    {
        id: "50100101",
        name: "AVION",
    },
    {
        id: "50100102",
        name: "HOSPEDAJE",
    }
    , {
        id: "50100103",
        name: "CASETAS",
    }, {
        id: "50100104",
        name: "ALIMENTOS",
    }, {

        id: "50100105",
        name: "COMUNICACIONES",
    }, {
        id: "50100107",
        name: "TRANSPORTE",
    }, {
        id: "50100151",
        name: "COMB Y LUBRICANTES",
    }, {
        id: "50100153",
        name: "MANT. Y REP. DE AUTOS",
    }, {
        id: "50100751",
        name: "PAPELERIA Y ARTS",
    }, {
        id: "50100161",
        name: "PROMOS LIGADOS A VENTAS",
    }, {
        id: "50100190",
        name: "SERVICIOS LIGADOS A VENTAS",
    }, {
        id: "50100169",
        name: "EVENTOS CLIENTES MKT",
    }, {
        id: "50100182",
        name: "LEGALIZACONES",
    }, {
        id: "50100956",
        name: "EST. DE TOLERANCIA",
    }, {
        id: "50100957",
        name: "RANCHO",
    }, {
        id: "50101001",
        name: "CAPACITACION",
    }, {
        id: "50101002",
        name: "MENSAJERIA",
    }, {
        id: "50101004",
        name: "NO DEDUCIBLE",
    }, {
        id: "50101010",
        name: "RENTA BODEGA MX",
    }, {
        id: "50101082",
        name: "CLV-C ORIGEN Y P. IMP",
    }, {
        id: "50100193",
        name: "ESTUDIOS DELEGADOS",
    }, {
        id: "40000040",
        name: "ALLIANCE",
    }

];

export default expencesKind;







