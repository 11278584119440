import * as WebBrowser from 'expo-web-browser';
import * as React from 'react';
import { Image, Platform, StyleSheet, Text, TouchableOpacity, View, Dimensions, ToastAndroid, Alert } from 'react-native';
import { ScrollView, FlatList } from 'react-native-gesture-handler';
import {
  LineChart,
  BarChart,
  PieChart,
  ProgressChart,
  ContributionGraph,
  StackedBarChart
} from "react-native-chart-kit";
import { Drawer, Button, Icon, Container, Content, Card, CardItem, Form, Picker, Body, Label, Left, Fab, ListItem, Right } from 'native-base';
import SideBar from './sideBar';
import leftIcon from '../components/LeftIcon';
import Axios from 'axios';
import { UserContext } from '../stores/Store.tsx';
import PreLoader from '../components/PreLoader';
import config from '../utils/config';
import { SafeAreaView } from 'react-native-safe-area-context';

var numeral = require('numeral');


export default function HomeScreen(props) {
  const { navigation } = props;
  const drawer = React.useRef(null);
  const [user] = React.useContext(UserContext);
  const [active, setActive] = React.useState(false);
  const [working, setWorking] = React.useState(false);
  const [displayChart, setDisplayChart] = React.useState(true);
  const [detailedData, setDetailedData] = React.useState([]);
  const [fabOpacity, setFabOpacity] = React.useState(1);
  const [data, setData] = React.useState({
    labels: ["January", "February", "March", "April", "May", "June"],
    datasets: [
      {
        data: [
          Math.random() * 100,
          Math.random() * 100,
          Math.random() * 100,
          Math.random() * 100,
          Math.random() * 100,
          Math.random() * 100
        ]
      }
    ]
  });

  SideBar.defaultProps = props;

  React.useLayoutEffect(() => {
    navigation.setOptions({
      headerLeft: () => (
        leftIcon(drawer, 'menu')
      ),
    });
  }, [navigation]);

  React.useEffect(() => {
    const unsubscribe = navigation.addListener('blur', () => {
      if (drawer.current._root._open) {
        drawer.current._root.close();
      }
    });

    return unsubscribe;
  }, [navigation]);

  React.useEffect(() => {
    setWorking(true);
    const uri = `${config.api.host}reports/deductibles`;
    Axios.get(uri, {
      headers: config.api.headers,
      params: {
        numeroEmpleado: user.noempleado,
      }
    })
      .then((response) => {
        console.log(response.data.results.length === 0);

        const labels = response.data.results.map((element) => element.NumeroPeriodo);
        const data = response.data.results.map((element) => element.total);
        setData({
          labels,
          // legend: ["L1", "L2", "L3"],
          datasets: [
            {
              data,
              color: (opacity = 1) => `rgba(134, 65, 244, ${opacity})`,
            },
          ],
          legend: labels
        });

        // if ( response.data.count === 0) {
        response.data.results.length === 0 && setDisplayChart(false);
        // }

        setDetailedData(response.data.results);
        setWorking(false);
      })
      .catch((error) => {
        console.log(error.response);
        Alert.alert('Error', `${JSON.stringify(error.response)}`);
        setWorking(false);
      })
  }, []);

  const showToastWithGravity = (msg, x, y) => {
    ToastAndroid.showWithGravity(
      msg,
      ToastAndroid.SHORT,
      ToastAndroid.CENTER,
    );
  };


  const renderPeriod = (element) => {
    return (
      <ListItem onPress={() => navigation.navigate('MovementsScreen', {
        periodID: element.periodID
      })}>
        <Left>
          <Text>{element.NumeroPeriodo}</Text>
        </Left>
        <Body>
          <Text>{numeral(element.total).format('$0,0.00')}</Text>
        </Body>
        <Right>
          <Icon name='md-arrow-forward' />
        </Right>
      </ListItem>
    )
  }

  if (working) {
    return <PreLoader />;
  }

  return (
    <Drawer
      ref={drawer}
      content={<SideBar />}
      tapToClose
    >

      <Container style={styles.container}>
        <SafeAreaView style={{ flex: 1, padding: 10 }}>
          <CardItem header>
            <Label>
              <Text>Indicador de gastos por periodo</Text>
            </Label>
          </CardItem>
          {/* <CardItem> */}
          {displayChart ?
            <BarChart
              data={data}
              width={(Dimensions.get("window").width)}
              height={300}
              style={{ marginLeft: -30 }}
              showValuesOnTopOfBars={true}
              showBarTops
              yAxisLabel="$ "
              // withHorizontalLabels={false}
              verticalLabelRotation={30}
              onDataPointClick={({ index, value, dataset, x, y }) => {
                showToastWithGravity(`
                  Periodo : ${data.labels[index]}
                  $ ${value}
                  `);
              }
              }
              chartConfig={{
                backgroundColor: "#fff",
                backgroundGradientFrom: "#fff",
                backgroundGradientTo: "#fff",
                fillShadowGradient: '#0B58A8',
                fillShadowGradientOpacity: 1,
                barPercentage: 1,
                barRadius: 1,
                decimalPlaces: 2, // optional, defaults to 2dp
                color: (opacity = 0) => `rgba(255, 255, 255, ${opacity})`,
                labelColor: (opacity = 1) => `rgba(0, 0, 0, ${opacity})`,
                style: {
                  borderRadius: 16,
                  marginLeft: -10
                },
                propsForLabels: {
                  fontSize: "10",
                  textAnchor: "middle",
                  // stroke: '#000'
                }
              }}
              bezier
            />
            : <Text>No hay información para mostrar</Text>}
          <FlatList
            data={detailedData}
            keyExtractor={(element) => element.NumeroPeriodo}
            ListHeaderComponent={
              displayChart ? <Label><Text>Desgloce de gastos</Text></Label> : null
            }
            renderItem={(element) => renderPeriod(element.item)}
            onScrollEndDrag={({ nativeEvent }) => {
              const sum = 1 - (nativeEvent.contentOffset.y * (0.1));
              setFabOpacity(sum > 0 ? sum : 0.2);
            }
            }
          />
        </SafeAreaView>
      </Container>
    </Drawer>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
  },
  developmentModeText: {
    marginBottom: 20,
    color: 'rgba(0,0,0,0.4)',
    fontSize: 14,
    lineHeight: 19,
    textAlign: 'center',
  },
  contentContainer: {
    paddingTop: 30,
  },
  welcomeContainer: {
    alignItems: 'center',
    marginTop: 10,
    marginBottom: 20,
  },
  welcomeImage: {
    width: 100,
    height: 80,
    resizeMode: 'contain',
    marginTop: 3,
    marginLeft: -10,
  },
  getStartedContainer: {
    alignItems: 'center',
    marginHorizontal: 50,
  },
  homeScreenFilename: {
    marginVertical: 7,
  },
  codeHighlightText: {
    color: 'rgba(96,100,109, 0.8)',
  },
  codeHighlightContainer: {
    backgroundColor: 'rgba(0,0,0,0.05)',
    borderRadius: 3,
    paddingHorizontal: 4,
  },
  getStartedText: {
    fontSize: 17,
    color: 'rgba(96,100,109, 1)',
    lineHeight: 24,
    textAlign: 'center',
  },
  tabBarInfoContainer: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    ...Platform.select({
      ios: {
        shadowColor: 'black',
        shadowOffset: { width: 0, height: -3 },
        shadowOpacity: 0.1,
        shadowRadius: 3,
      },
      android: {
        elevation: 20,
      },
    }),
    alignItems: 'center',
    backgroundColor: '#fbfbfb',
    paddingVertical: 20,
  },
  tabBarInfoText: {
    fontSize: 17,
    color: 'rgba(96,100,109, 1)',
    textAlign: 'center',
  },
  navigationFilename: {
    marginTop: 5,
  },
  helpContainer: {
    marginTop: 15,
    alignItems: 'center',
  },
  helpLink: {
    paddingVertical: 15,
  },
  helpLinkText: {
    fontSize: 14,
    color: '#2e78b7',
  },
});
