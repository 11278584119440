import React from 'react';
import { Image } from 'react-native';
import { Text, View } from 'native-base';

const logo = require('../assets/images/header.png');

function LogoTitle(props) {
  const { title } = props;
  if (title) {
    return (
      <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
        <Text style={{ color: '#fff', fontWeight: 'bold', fontSize: 20 }}>{title}</Text>
        <Image
          source={logo}
          style={{
            width: 150, height: 55, alignSelf: 'flex-end',
          }}
        />

      </View>
    );
  }
  return (
    <Image
      source={logo}
      style={{
        width: 150, height: 55, alignSelf: 'center', marginRight: 50
      }}
    />
  );
}

export default LogoTitle;
