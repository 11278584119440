import * as React from 'react';
import { Platform, StyleSheet, Alert, ToastAndroid, ProgressViewIOS, ProgressBarAndroid, Dimensions } from 'react-native';
import { Container, Content, Button, View, Text, Form, Item, Label, Input, Picker, Icon, Textarea, Header, Card, CardItem, Body } from 'native-base';
import { Overlay } from 'react-native-elements';
import PreLoader from '../../components/PreLoader';
import { UserContext } from '../../stores/Store.tsx';
import Axios from 'axios';
import config from '../../utils/config';
import expencesKind from '../../constants/ExpenceKind';
import movements from '../../constants/movements';
import * as DocumentPicker from 'expo-document-picker';
import { removeNulls, hasNulls, validateNan, validateIntNan } from '../../utils/checkNulls';
import * as FileSystem from 'expo-file-system';
import uploadImageAsync from '../../utils/uploadFile';
import PDFReader from 'rn-pdf-reader-js'

const { width } = Dimensions.get('window')

const XMLParser = require('react-xml-parser');
var parseString = require('react-native-xml2js').parseString;

export default function CreateDeducibleExpence(props) {
  const { navigation } = props;
  const [user] = React.useContext(UserContext);
  const [working, setWorking] = React.useState(false);
  const [visible, setVisible] = React.useState(false);
  const [visibleEmployee, setVisibleEmployee] = React.useState(false);
  const [visibleBill, setVisibleBill] = React.useState(false);
  const [uploading, setUploading] = React.useState(false);
  const [uploadedFiles, setUploadedFiles] = React.useState({
    xml: null,
    pdf: null,
    xmlFile: null,
    pdfFile: null
  })
  const [period, setPeriod] = React.useState({
    "periodoId": "202006301",
    "nosemana": "6",
    "startdate": "2020-06-01",
    "endate": "2020-06-30",
    "contadorperiodo": 56
  });
  const [bill, setBill] = React.useState({
    "Descuento": undefined,
    "Folio": undefined,
    "FormaPago": undefined,
    "IVA": undefined,
    "MetodoPago": undefined,
    "Moneda": undefined,
    "Nombre": undefined,
    "Rfc": undefined,
    "SubTotal": undefined,
    "TasaOCuota": undefined,
    "TipoCambio": undefined,
    "Total": undefined,
    "UUID": undefined,
    "totalImpuestosRetenidos": undefined,

  });

  const [dataForm, setDataForm] = React.useState({
    "TipoMovimiento": null,
    "Comentario": null,
    "CuentaContable": null,
    "CantidadComensales": 0,
    "kilometraje": 0,
  });

  // React.useEffect(() => {
  //   console.log(user);

  // }, [bill]);

  React.useLayoutEffect(() => {
    setWorking(true);
    const date = new Date();
    const lastDayOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
    const ActualPeriod = `${date.getFullYear()}${date.getMonth() + 1 > 9 ? date.getMonth() + 1 : `0${date.getMonth() + 1}`}${lastDayOfMonth}1`;

    // const uri = `${config.api.host}periods/500`;
    const uri = `${config.api.host}periods/${ActualPeriod}`;
    Axios.get(uri, {
      headers: config.api.headers,
    })
      .then((response) => {
        setWorking(false);
        setPeriod(response.data);
      })
      .catch((error) => {
        console.log(error.response);
        setWorking(false);
        if (error.response && error.response.status === 404) {
          Alert.alert('Error', 'El periodo no es valido, contacta a tu administrador');
        } else {
          Alert.alert('Error', `${JSON.stringify(error.response)}`);
        }

      })
    // setWorking(false);
  }, []);

  const formatDate = (date) => {
    let Fdate = new Date(date);

    let day = Fdate.getDate()
    let month = Fdate.getMonth() + 1
    let year = Fdate.getFullYear()

    if (month < 10) {
      return `${year}-0${month}-${day}`;
    } else {
      return `${year}-${month}-${day}`;
    }
  }

  const changeFileName = async (reference, kind) => {
    const { pdfFile, xmlFile } = uploadedFiles;
    const dataFile = kind === 'pdf' ? pdfFile : xmlFile;
    let extension = dataFile.name.split('.').pop();
    let filename = dataFile.uri.split('/').pop();
    let newUri = dataFile.uri.replace(filename, `${reference}.${extension}`)
    console.log(reference);

    await FileSystem.copyAsync({
      from: dataFile.uri,
      to: newUri
    })
      .then(async () => {
        await FileSystem.getInfoAsync(newUri)
          .then(async (response) => {
            if (response.exists) {
              const nFile = dataFile;
              nFile.uri = newUri;
              console.log(nFile);
              await kind === 'pdf' ? uploadPDF(nFile) : uploadXML(nFile);
            }

          })
          .catch((error) => {
            console.log('error', error);
            // Alert.alert('Error', `${JSON.stringify(error)}`);
          })

      })
      .catch((error) => {
        console.log('error', error);
        // Alert.alert('Error', `${JSON.stringify(error)}`);

      })
  }

  const saveDeducible = () => {

    const { xmlFile, pdfFile } = uploadedFiles;
    const reg = RegExp('(^|)(\.pdf|.xml)+');
    const nameXml = xmlFile.name.replace(reg, '');
    const namePdf = pdfFile.name.replace(reg, '');
    let Fdate = new Date(bill.Fecha);
    if (nameXml !== namePdf) {
      Alert.alert('Error', 'Los nombres de los archivos PDF y XML no coinciden.');
      setWorking(false);
      return;
    }
    const mesfactura = Fdate.getMonth();
    const mesactual = new Date().getMonth();

    if (mesfactura !== mesactual) {
      Alert.alert('Error', 'Esta factura no es valida para el periodo.');
      setWorking(false);
      return;
    }

    if (!(mesfactura === mesactual - 1 || mesfactura === mesactual || mesfactura === 12 || mesfactura === mesactual - 2)) {
      Alert.alert('Error', 'Esta factura no es valida para ningun periodo.');
      setWorking(false);
      return;
    }
    setUploading(true);

    const data = {
      "uuid": bill.UUID,
      "NumeroPeriodo": period.periodoId,
      "numeroEmpleado": user.noempleado,
      "rfc": bill.Rfc,
      "RazonSocial": bill.Nombre,
      "subTotal": validateNan(bill.SubTotal),
      "iva": validateNan(bill.IVA),
      "TasaImpuesto": validateNan(bill.TasaImpuesto),
      "total": validateNan(bill.Total),
      "fecha": formatDate(bill.Fecha),
      "estado": 0,
      "comentarios": dataForm.Comentario,
      "CantidadComensales": validateIntNan(dataForm.CantidadComensales),
      "kilometraje": validateNan(dataForm.kilometraje),
      "RetencionImporte": validateNan(bill.Importe),
      "RetencionImpuesto": validateNan(bill.Impuesto),
      "RetISR": validateNan(bill.ISR),
      "TotImpRet": validateNan(bill.totalImpuestosRetenidos),
      "Aprobado2": 0,
      "Aprobado3": 0,
      "CentroCosto": user.centrocosto,
      "MetodoPago": bill.MetodoPago,
      "FormaPago": bill.FormaPago,
      "TipoMovimiento": dataForm.TipoMovimiento,
      "CuentaContable": dataForm.CuentaContable,
      "Mostrar": true
    };

    const cleanedData = removeNulls(data);
    const uriAttachments = `${config.api.host}attachments`;

    Promise.all([
      changeFileName(data.uuid, 'pdf'),
      changeFileName(data.uuid, 'xml'),
    ])
      .then(() => {
        console.log('uploading files');
        uploadImageAsync(uploadedFiles.xmlFile, uriAttachments, data.uuid, 'editorImage', user.noempleado, period.periodoId)
          .then(() => {
            uploadImageAsync(uploadedFiles.pdfFile, uriAttachments, data.uuid, 'editorImage', user.noempleado, period.periodoId)
              .then((response) => {

                cleanedData.PathFileName = response.data.url;
                console.log(cleanedData);
                // cleanedData.PathFileName = response.map((element) => element.data.url.includes('pdf'));
                setWorking(true);
                const uri = `${config.api.host}deductible`;
                Axios.post(uri, cleanedData, {
                  headers: config.api.headers,
                })
                  .then((response) => {
                    Alert.alert(
                      'Deducible',
                      'Deducible registrado correctamente', [
                      {
                        text: 'OK',
                        onPress: () => navigation.pop()
                      }
                    ]
                      ,
                      { cancelable: false });
                    setWorking(false);
                  })
                  .catch((error) => {
                    console.log(error.response);
                    Alert.alert('Error', `${JSON.stringify(error.response.data)}`);
                    setWorking(false);
                  })
              })
              .catch((error) => {
                console.log(error);
                Alert.alert('Error al subir PDF', `${JSON.stringify(error.response.data)}`);
                setUploading(false);
              })
              .finally(() => {
                setUploading(false);
              });
          })
          .catch((error) => {
            console.log(JSON.stringify(error.response));
            Alert.alert('Error al subir XML', `${JSON.stringify(error.response.data)}`);
            setUploading(false);
          });
        // Promise.all([
        //   uploadImageAsync(uploadedFiles.xmlFile, uriAttachments, data.uuid, 'editorImage', user.noempleado, period.periodoId),
        //   uploadImageAsync(uploadedFiles.pdfFile, uriAttachments, data.uuid, 'editorImage', user.noempleado, period.periodoId),
        // ])

      })
      .catch((error) => {
        console.log(JSON.stringify(error));
        Alert.alert('Error de lectura/escritura', `${JSON.stringify(error)}`);
        setUploading(false);
      });


  }

  const toggleOverlay = () => {
    setVisible(!visible);
  };

  const toggleOverlayEmployee = () => {
    setVisibleEmployee(!visibleEmployee);
  }
  const toggleOverlayBill = () => {
    setVisibleBill(!visibleBill);
  }
  const OverlayMenu = () => (
    <Card style={{ margin: 20, padding: 10 }} transparent>
      <CardItem header>
        <Label>Acciones</Label>
      </CardItem>
      <CardItem>
        <Button>
          <Icon name='md-cloud-upload' />
          <Text>CARGAR XML</Text>
        </Button>
      </CardItem>
      <CardItem>
        <Button danger >
          <Icon name='md-cloud-upload' />
          <Text>CARGAR PDF</Text>
        </Button>
      </CardItem>
    </Card>
  )

  const OverlayEmployee = () => (
    <Card style={{ margin: 20, padding: 10 }} transparent >
      <CardItem header>
        <Label>Datos generales del usuario</Label>
      </CardItem>
      <CardItem>
        <Text>{`No. Empleado: ${user.noempleado}`}</Text>
      </CardItem>
      <CardItem>
        <Text>{`Nombre: ${user.nombre}`}</Text>
      </CardItem>
      <CardItem>
        <Text>{`Departamento: ${user.departamento}`}</Text>
      </CardItem>
      <CardItem>
        <Text>{`Centro de costo: ${user.centrocosto}`}</Text>
      </CardItem>
      <CardItem>
        <Text>{`Fecha de emisión: ${new Date().toLocaleDateString()}`}</Text>
      </CardItem>
      <CardItem>
        <Text>{`Semana: ${period.nosemana}`}</Text>
      </CardItem>
    </Card>
  );

  const OverlayBill = () => (
    <Card style={{ margin: 20, padding: 10 }} transparent >
      <CardItem header>
        <Label>Datos de la factura</Label>
      </CardItem>
      <CardItem>
        <Text>{`Folio: ${bill.Folio}`}</Text>
      </CardItem>
      <CardItem>
        <Text>{`UUID: ${bill.UUID}`}</Text>
      </CardItem>
      <CardItem>
        <Text>{`Emisor: ${bill.Nombre}`}</Text>
      </CardItem>
      <CardItem>
        <Text>{`RFC: ${bill.Rfc}`}</Text>
      </CardItem>
      <CardItem>
        <Text>{`Tasa de impuesto: ${bill.TasaOCuota}`}</Text>
      </CardItem>
      <CardItem>
        <Text>{`Subtotal: ${bill.SubTotal}`}</Text>
      </CardItem>
      {bill.Descuento ? <CardItem>
        <Text>{`Descuento: ${bill.Descuento}`}</Text>
      </CardItem> : null}
      <CardItem>
        <Text>{`IVA: ${bill.IVA}`}</Text>
      </CardItem>
      {bill.ISR ? <CardItem>
        <Text>{`Ret. ISR: ${bill.ISR}`}</Text>
      </CardItem> : null}
      {bill.ish ? <CardItem>
        <Text>{`ISH: ${bill.ish}`}</Text>
      </CardItem> : null}
      {bill.RetIVA ? <CardItem>
        <Text>{`Ret. IVA: ${bill.RetIVA}`}</Text>
      </CardItem> : null}
      {bill.TUA ? <CardItem>
        <Text>{`TUA: ${bill.TUA}`}</Text>
      </CardItem> : null}
      {bill.totalImpuestosRetenidos ? <CardItem>
        <Text>{`Total Imp. Ret.: ${bill.totalImpuestosRetenidos}`}</Text>
      </CardItem> : null}
      <CardItem>
        <Text>{`Total: ${bill.Total}`}</Text>
      </CardItem>
    </Card>
  );

  const RenderMovements = () => {
    if (user.noempleado.includes('R')) {
      delete movements[2];
    } else {
      delete movements[0];
      delete movements[1];
    }
    return movements.map((element) => (<Picker.Item label={element.name} value={element.name} key={element.id} />))
  }

  const Comprobante = async (parsedXmlComprobante, FormaPago) => {
    if (parsedXmlComprobante) {
      const { attributes } = parsedXmlComprobante;

      const { Folio, SubTotal, Descuento, Moneda, MetodoPago, TipoCambio, Total, Fecha } = attributes;
      console.log(FormaPago);

      // if (Descuento) {
      //   SubTotal = parseFloat(SubTotal) - parseFloat(Descuento);
      // }
      // if (Moneda === "USD" || Moneda === "EUR") {
      //   const searchTerm = RegExp('(^|)(\€|\$)+');
      //   Total.replace(searchTerm, '');
      //   SubTotal.replace(searchTerm, '');
      //   if (TipoCambio) {
      //     Total = parseFloat(Total) * parseFloat(TipoCambio);
      //     SubTotal = parseFloat(SubTotal) * parseFloat(TipoCambio);
      //   }

      // }
      const value = { Folio, SubTotal, Descuento, Moneda, MetodoPago, FormaPago, TipoCambio, Total, Fecha };
      await setBill(prev => {
        return { ...prev, ...value }
      });
    } else {
      await true;
    }
  }

  const Emisor = async (parsedXmlEmisor) => {
    if (parsedXmlEmisor) {
      const { attributes } = parsedXmlEmisor;
      const { Rfc, Nombre } = attributes;
      const value = { Rfc, Nombre };
      await setBill(prev => {
        return { ...prev, ...value }
      });
    } else {
      await true;
    }
  }

  const TimbreFiscalDigital = async (parsedXmlTimbreFiscalDigital, StrUUID) => {
    if (parsedXmlTimbreFiscalDigital) {
      const { attributes } = parsedXmlTimbreFiscalDigital;
      const { UUID } = attributes;
      const value = { UUID: UUID ? UUID : StrUUID };
      await setBill(prev => {
        return { ...prev, ...value }
      });
    } else {
      await false;
    }
  }

  const Traslado = async (parsedXmlTraslado) => {
    if (parsedXmlTraslado) {
      const { attributes } = parsedXmlTraslado;
      const { Impuesto, Base, Importe, TasaOCuota } = attributes;
      if (Impuesto === "002" && Base) {
        const iva = parseFloat(Importe);
        const value = { IVA: iva, TasaOCuota };
        await setBill(prev => {
          return { ...prev, ...value }
        });
      } else if (Impuesto === "003") {
        if (parseFloat(bill.SubTotal) === 0) {
          const value = { SubTotal: parseFloat(Importe) };
          await setBill(prev => {
            return { ...prev, ...value }
          });
        }
        else {
          const subttal = parseFloat(bill.SubTotal) + parseFloat(Importe);
          const value = { SubTotal: subttal };
          await setBill(prev => {
            return { ...prev, ...value }
          });
        }
      } else {
        await true;
      }

    } else {
      await true;
    }
  }

  const Impuestos = async (parsedXmlImpuestos) => {
    if (parsedXmlImpuestos) {
      const { attributes } = parsedXmlImpuestos;
      const { totalImpuestosRetenidos } = attributes;
      const value = { totalImpuestosRetenidos };
      await setBill(prev => {
        return { ...prev, ...value }
      });
    } else {
      await true;
    }
  }

  const Retencion = async (parsedXmlImpuestos) => {
    if (parsedXmlImpuestos) {
      const { attributes } = parsedXmlImpuestos;
      const { Impuesto, Importe } = attributes;
      if (Impuesto === 'ISR') {
        const value = { Impuesto, ISR: Importe, Importe };
        await setBill(prev => {
          return { ...prev, ...value }
        });
      } else {
        const value = { Impuesto, RetIVA: Importe, Importe };
        await setBill(prev => {
          return { ...prev, ...value }
        });
      }

    } else {
      await true;
    }
  }

  const RetencionesLocales = async (parsedXmlRetencionesLocales) => {
    if (parsedXmlRetencionesLocales) {
      const { attributes } = parsedXmlRetencionesLocales;
      const { importe, Impuesto: impLocRetenido } = attributes;
      const value = { importe, Impuesto: impLocRetenido };
      await setBill(prev => {
        return { ...prev, ...value }
      });
    } else {
      await true;
    }
  }

  const TrasladosLocales = async (parsedXmlTrasladosLocales) => {
    if (parsedXmlTrasladosLocales) {
      const { attributes } = parsedXmlTrasladosLocales;
      const { Importe } = attributes;
      if (Importe) {
        const searchTerm = RegExp('(^|)(\€|\$)+');
        const ish = Importe.replace(searchTerm, '');
        const subtotal = `${bill.SubTotal}`;
        const subish = subtotal.replace(searchTerm, '');
        let suma = parseFloat(subish) + parseFloat(ish)
        const val = `${ish}`;
        const value = { ish: val };
        await setBill(prev => {
          return { ...prev, ...value }
        });
      } else {
        await true;
      }
    } else {
      await true;
    }
  }

  const Concepto = async (parsedXmlConcepto) => {
    if (parsedXmlConcepto) {
      const { attributes } = parsedXmlConcepto;
      const { Descripcion, Importe } = attributes;
      if (Descripcion === 'TUA Tarifa Única de Aeropuerto') {
        const TUADouble = parseFloat(Importe);
        const value = { TUA: TUADouble };
        await setBill(prev => {
          return { ...prev, ...value }
        });
      } else {
        await true;
      }

    } else {
      await true;
    }
  }

  const getFields = (xml, file, FormaPago, UUID) => {
    setWorking(true);
    const parsedXmlComprobante = xml.getElementsByTagName('cfdi:Comprobante')[0];
    const parsedXmlEmisor = xml.getElementsByTagName('cfdi:Emisor')[0];
    const parsedXmlReceptor = xml.getElementsByTagName('cfdi:Receptor')[0];
    const parsedXmlTimbreFiscalDigital = xml.getElementsByTagName('tfd:TimbreFiscalDigital')[0];
    const parsedXmlTraslado = xml.getElementsByTagName('cfdi:Traslado')[0];
    const parsedXmlImpuestos = xml.getElementsByTagName('cfdi:Impuestos')[0];
    const parsedXmlRetencion = xml.getElementsByTagName('cfdi:Retencion')[0];
    const parsedXmlRetencionesLocales = xml.getElementsByTagName('cfdi:RetencionesLocales')[0];
    const parsedXmlTrasladosLocales = xml.getElementsByTagName('implocal:TrasladosLocales')[0];
    const parsedXmlConcepto = xml.getElementsByTagName('cfdi:Concepto')[0];

    //Validación para el RFC de virbac
    if (parsedXmlReceptor) {
      const { attributes } = parsedXmlReceptor;
      const { Rfc } = attributes;
      if (Rfc !== 'VME990302RH7') {
        Alert.alert('Error', 'La Factura que intenta cargar no es con el RFC para Virbac México S.A. de C.V.');
        setWorking(false);
        return;
      }
    }

    Promise.all([
      Comprobante(parsedXmlComprobante, FormaPago),
      Emisor(parsedXmlEmisor),
      TimbreFiscalDigital(parsedXmlTimbreFiscalDigital, UUID),
      Traslado(parsedXmlTraslado),
      Impuestos(parsedXmlImpuestos),
      Retencion(parsedXmlRetencion),
      RetencionesLocales(parsedXmlRetencionesLocales),
      TrasladosLocales(parsedXmlTrasladosLocales),
      Concepto(parsedXmlConcepto),
    ])
      .then(() => {
        setWorking(false);
        uploadXML(file);
      })
      .catch((error) => {
        console.log(error);
        setWorking(false);
      })
      .finally(toggleOverlayBill)
  }

  const parseXML = (file) => {
    var url = file.uri
    fetch(url)
      .then((response) => response.text())
      .then((responseText) => {
        const ind = responseText.search('FormaPago');
        const FormaPago = responseText.substr(ind + 11, 2);

        const indUUID = responseText.search('UUID');
        const UUID = responseText.substr(indUUID + 6, 36);

        const xml = new XMLParser().parseFromString(responseText);
        const parsedXml = xml.getElementsByTagName('cfdi:Comprobante')[0];
        const { attributes } = parsedXml
        const { MetodoPago } = attributes;
        MetodoPago === 'PUE' && getFields(xml, file, FormaPago, UUID);
      })
      .catch((error) => {
        console.log('Error fetching the feed: ', error);
      });
  }

  const uploadPDF = (file) => {
    setUploadedFiles(prev => { return { ...prev, ...{ pdf: true, pdfFile: file } } });
  }

  const uploadXML = (file) => {
    setUploadedFiles(prev => { return { ...prev, ...{ xml: true, xmlFile: file } } });
  }

  const selectFile = (kind = null) => {
    const type = kind ? kind : '*/*';
    DocumentPicker.getDocumentAsync({
      type
    })
      .then((result) => {

        if (result.type !== 'cancel') {
          kind.includes('xml') && parseXML(result);
          kind.includes('pdf') && uploadPDF(result);
        }
      })
      .catch((error) => {
        Alert.alert('Ha ocurrido un error al seleccionar el archivo', JSON.stringify(error))
      })
  }

  const showToastWithGravity = (msg) => {
    ToastAndroid.showWithGravity(
      msg,
      ToastAndroid.SHORT,
      ToastAndroid.CENTER
    );
  };

  if (uploading) {
    return (
      <View style={{
        flex: 1,
        justifyContent: 'center',
        padding: 10,
        // alignItems: 'center'
      }}>
        <View style={{
          alignItems: 'center'
        }}>
          <Text>Subiendo archivos...</Text>
          <Text>Por favor espera a que la carga finalice</Text>
        </View>
        {
          Platform.OS === 'ios' ?
            <ProgressViewIOS progressTintColor="#2196F3" />
            : <ProgressBarAndroid styleAttr="Horizontal" color="#2196F3" />
        }

      </View>
    )
  }

  const displayPDF = () => {
    if (!uploadedFiles.pdfFile) return null
    return (
      <View>
        <PDFReader
          source={{
            uri: uploadedFiles.pdfFile.uri,
          }}
          style={{ flex: 1, width }}
          withPinchZoom
        />
        <View style={{ position: 'absolute', zIndex: 2, marginLeft: 10, marginTop: 10 }}>
          <Icon onPress={() => toggleOverlay()} name='close' />
        </View>
      </View>

    )
  }

  if (working) {
    return <PreLoader />;
  }

  const kinds = expencesKind.map((element) => (<Picker.Item label={element.name} value={element.id} key={element.id} />))

  return (
    <Container style={styles.container}>
      <Overlay isVisible={visible} onBackdropPress={toggleOverlay}>
        {displayPDF()}
      </Overlay>
      <Overlay isVisible={visibleEmployee} onBackdropPress={toggleOverlayEmployee}>
        {OverlayEmployee()}
      </Overlay>
      <Overlay isVisible={visibleBill} onBackdropPress={toggleOverlayBill}>
        {OverlayBill()}
      </Overlay>
      <Header style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }} transparent>
        <Button transparent bordered onPress={toggleOverlayEmployee}>
          <View style={{ flexDirection: 'column', alignItems: 'center' }}>
            <Icon style={{ color: '#000' }} name='md-person' />
            <Text style={{ color: '#000' }}>USUARIO</Text>
          </View>

        </Button>
        <Button transparent bordered onPress={() => {
          if (!uploadedFiles.xml) {
            showToastWithGravity('Primero carga un XML');
          } else {
            toggleOverlayBill();
          }

        }}>
          <View style={{ flexDirection: 'column', alignItems: 'center' }}>
            <Icon style={{ color: '#000' }} name='md-cash' />
            <Text style={{ color: '#000' }}>DATOS FACTURA</Text>
          </View>

        </Button>
        <Button
          disabled={hasNulls(dataForm)}
          style={{ backgroundColor: hasNulls(uploadedFiles) ? 'gray' : '#0B58A8' }}
          onPress={() => {
            if (hasNulls(uploadedFiles)) {
              const msg = `Primero debes cargar ${uploadedFiles.xml ? '' : 'XML'} ${uploadedFiles.pdf ? '' : 'PDF'}`;
              showToastWithGravity(msg);

            } else {
              saveDeducible();
            }

          }}>
          <View style={{ flexDirection: 'column', alignItems: 'center' }}>
            <Icon name='md-save' />
            <Text >GUARDAR</Text>
          </View>

        </Button>
      </Header>
      <Content padder>
        <Form>
          <Item picker style={{ marginLeft: 15, marginRight: 15 }}>
            <Label>Movimiento: </Label>
            <Picker
              mode="dropdown"
              iosIcon={<Icon name="arrow-down" />}
              style={{ width: undefined, margin: 10 }}
              placeholder="Select your SIM"
              placeholderStyle={{ color: "#bfc6ea" }}
              placeholderIconColor="#007aff"
              selectedValue={dataForm.TipoMovimiento}
              onValueChange={(TipoMovimiento) => {
                setDataForm(prev => {
                  return { ...prev, ...{ TipoMovimiento } }
                })
              }}
            >
              <Picker.Item label='---' value={null} />
              {RenderMovements()}
            </Picker>
          </Item>
          <Item picker style={{ marginLeft: 15, marginRight: 15 }}>
            <Label>Tipo de gasto: </Label>
            <Picker
              mode="dropdown"
              iosIcon={<Icon name="arrow-down" />}
              style={{ width: undefined, margin: 10 }}
              placeholder="Select your SIM"
              placeholderStyle={{ color: "#bfc6ea" }}
              placeholderIconColor="#007aff"
              selectedValue={dataForm.CuentaContable}
              onValueChange={(CuentaContable) => {
                setDataForm(prev => {
                  return { ...prev, ...{ CuentaContable } }
                })
              }}
            >
              <Picker.Item label='---' value={null} />
              {kinds}
            </Picker>
          </Item>
          {dataForm.CuentaContable === '50100104' ? <Item>
            <Label>Comensales: </Label>
            <Input
              placeholder="Comensales"
              keyboardType={"numeric"}
              defaultValue={0}
              value={dataForm.CantidadComensales}
              onChangeText={(CantidadComensales) => {
                setDataForm(prev => {
                  return { ...prev, ...{ CantidadComensales } }
                })
              }} />
          </Item> : null}
          {dataForm.CuentaContable === '50100151' ? <Item>
            <Label>kilometraje: </Label>
            <Input
              placeholder="kilometraje"
              keyboardType={"numeric"}
              defaultValue={0}
              value={dataForm.kilometraje}
              onChangeText={(kilometraje) => {
                setDataForm(prev => {
                  return { ...prev, ...{ kilometraje } }
                })
              }} />
          </Item> : null}
          <Textarea
            style={{ marginLeft: 15, marginRight: 15, marginTop: 15 }}
            rowSpan={5}
            bordered
            placeholder="Comentarios"
            value={dataForm.Comentario}

            onChangeText={(Comentario) => {
              setDataForm(prev => {
                return { ...prev, ...{ Comentario } }
              })
            }}
          />

        </Form>
        <Card style={{ margin: 20, padding: 10, flexDirection: 'row', alignSelf: "center" }} transparent>
          <CardItem>
            <Button onPress={() => {
              selectFile('text/xml');
            }}>
              <Icon name='md-cloud-upload' />
              <Text>CARGAR XML</Text>
            </Button>
          </CardItem>
          <CardItem>
            <Button danger onPress={() => {
              selectFile('application/pdf');
            }}>
              <Icon name='md-cloud-upload' />
              <Text>CARGAR PDF</Text>
            </Button>
          </CardItem>
        </Card>
        <Card style={{ margin: 20, padding: 10, flexDirection: 'row', alignSelf: "center" }} transparent>
          <CardItem>
            <Button danger onPress={() => {
              if (uploadedFiles.pdfFile) {
                console.log(uploadedFiles.pdfFile);

                toggleOverlay()
              }
            }}>
              <Icon name='md-eye' />
              <Text>VER PDF</Text>
            </Button>
          </CardItem>
        </Card>
      </Content>
    </Container >
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
  },
  developmentModeText: {
    marginBottom: 20,
    color: 'rgba(0,0,0,0.4)',
    fontSize: 14,
    lineHeight: 19,
    textAlign: 'center',
  },
  contentContainer: {
    paddingTop: 30,
  },
  welcomeContainer: {
    alignItems: 'center',
    marginTop: 10,
    marginBottom: 20,
  },
  welcomeImage: {
    width: 100,
    height: 80,
    resizeMode: 'contain',
    marginTop: 3,
    marginLeft: -10,
  },
  getStartedContainer: {
    alignItems: 'center',
    marginHorizontal: 50,
  },
  homeScreenFilename: {
    marginVertical: 7,
  },
  codeHighlightText: {
    color: 'rgba(96,100,109, 0.8)',
  },
  codeHighlightContainer: {
    backgroundColor: 'rgba(0,0,0,0.05)',
    borderRadius: 3,
    paddingHorizontal: 4,
  },
  getStartedText: {
    fontSize: 17,
    color: 'rgba(96,100,109, 1)',
    lineHeight: 24,
    textAlign: 'center',
  },
  tabBarInfoContainer: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    ...Platform.select({
      ios: {
        shadowColor: 'black',
        shadowOffset: { width: 0, height: -3 },
        shadowOpacity: 0.1,
        shadowRadius: 3,
      },
      android: {
        elevation: 20,
      },
    }),
    alignItems: 'center',
    backgroundColor: '#fbfbfb',
    paddingVertical: 20,
  },
  tabBarInfoText: {
    fontSize: 17,
    color: 'rgba(96,100,109, 1)',
    textAlign: 'center',
  },
  navigationFilename: {
    marginTop: 5,
  },
  helpContainer: {
    marginTop: 15,
    alignItems: 'center',
  },
  helpLink: {
    paddingVertical: 15,
  },
  helpLinkText: {
    fontSize: 14,
    color: '#2e78b7',
  },
});
