import * as React from 'react';
import { Container, Content, Text, Button, View, Footer, Form, Item, Picker, Label, Icon, ListItem, Input, Header } from 'native-base';
import PreLoader from '../../components/PreLoader';
import config from '../../utils/config';
import Axios from 'axios';
import { Alert, Dimensions, ProgressViewIOS, ProgressBarAndroid, Platform, ActivityIndicator } from 'react-native';
import { FlatList } from 'react-native-gesture-handler';

const deviceHeight = Dimensions.get('window').height;
const deviceWidth = Dimensions.get('window').width;

const SheetsScreen = (props) => {
    const [working, setWorking] = React.useState(false);
    const [synchronizing, setSynchronizing] = React.useState(false);
    const [periods, setPeriods] = React.useState([]);
    const [Segperiods, setSegPeriods] = React.useState([]);
    const [selectedPeriod, setSelectedPeriod] = React.useState(null);
    const [text, setText] = React.useState('');
    // const [selectedYear, setSelectedYear] = React.useState(new Date().getFullYear());
    // const [date, setDate] = React.useState(new Date(new Date().getFullYear(), 0, 1));
    // const year = 2019;
    // const years = Array.from(new Array((new Date().getFullYear() + 1) - year), (val, index) => index + year).reverse();

    React.useEffect(() => {
        setWorking(true);
        // const startdate = `${date.getFullYear()}-${date.getMonth() < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1}-${date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()}`
        const uri = `${config.api.host}periods`;
        Axios.get(uri, {
            headers: config.api.headers,
            // params: {
            //     startdate
            // }
        })
            .then((response) => {
                console.log(response.data);
                setPeriods(response.data.results);
                paginateData(response.data.results);

            })
            .catch((error) => {
                console.log(error);
                Alert.alert('Error', JSON.stringify(error.response))
                setWorking(false);
            })
    }, []);

    const paginateData = (totalData) => {
        const data = totalData.slice(0, 9);
        setSegPeriods(data);
        setWorking(false);
    }

    const handleSearch = (term) => {
        setText(term);
        var re = new RegExp(term, "ig");
        const found = periods.filter(element => element.periodoId.match(re));

        const data = found.slice(0, 9);
        setSegPeriods(data);
    }

    const RenderHeader = () => {
        return (
            <View style={{ flex: 1, alignContent: 'center', alignItems: 'center', backgroundColor: 'white' }}>
                <Item>
                    <Icon name="ios-search" />
                    <Input placeholder="Buscar" autoFocus value={text} onChangeText={(term) => handleSearch(term)} />
                </Item>
            </View>
        );
    };

    const makeSync = () => {
        setSynchronizing(true);
        const uri = `${config.api.host}reports/gsheets/deductibles`;
        Axios.post(uri, { periodId: selectedPeriod }, {
            headers: config.api.headers,
        })
            .then((response) => {
                setSynchronizing(false);
                if (Platform.OS === 'web') {
                    alert(`La sincronización ha terminado correctamente con un total de ${response.data.syncDocs}`);
                } else {
                    Alert.alert('Completado', `La sincronización ha terminado correctamente con un total de ${response.data.syncDocs}`);
                }

            })
            .catch((error) => {
                setSynchronizing(false);
                console.log(error.response);
                if (Platform.OS === 'web') {
                    alert(`Error ${JSON.stringify(error.response.data)}`);
                } else {
                    Alert.alert('Error', JSON.stringify(error.response.data));
                }
            })
    };

    const onChange = (val) => {
        setSelectedPeriod(val);
    };


    if (working) {
        return <PreLoader />;
    }

    const RenderProgress = () => {
        if (Platform.OS === 'ios') return <ProgressViewIOS progressTintColor="#2196F3" />;
        if (Platform.OS === 'android') return <ProgressBarAndroid styleAttr="Horizontal" color="#2196F3" />
        if (Platform.OS === 'web') return <ActivityIndicator size={100} />
    }

    if (synchronizing) {
        return (
            <View style={{
                flex: 1,
                justifyContent: 'center',
                padding: 10,
                // alignItems: 'center'
            }}>
                <View style={{
                    alignItems: 'center'
                }}>
                    <Text>Sincronizando datos a Google Sheets...</Text>
                    <Text>Por favor espera a que la sincronización finalice</Text>
                </View>
                <RenderProgress />

            </View>
        )
    }

    const isSelected = (item) => {
        console.log(item === selectedPeriod);
        return item === selectedPeriod;
    }

    const Periods = Segperiods.map((element) => <Picker.Item value={element.periodoId} key={element.periodoId} label={element.periodoId} />)

    if (Platform.OS === 'web') {
        return (
            <Container>
                <Header searchBar rounded transparent>
                    <RenderHeader />
                </Header>

                <Content padder contentContainerStyle={{ flex: 1, justifyContent: 'center', flexDirection: 'row' }}>
                    <Form>
                        <Label><Text style={{ fontSize: 20 }}>Selecciona un periodo a sincronizar</Text></Label>
                        <Item>
                            <Picker
                                mode="dialog"
                                style={{ width: Dimensions.get('window').width / 3 }}
                                iosIcon={<Icon name="arrow-down" />}
                                selectedValue={selectedPeriod}
                                onValueChange={(value) => setSelectedPeriod(value)}
                            >
                                <Picker.Item label="----" value={null} />
                                {Periods}
                            </Picker>
                        </Item>

                    </Form>
                    {/* <View style={{ width: deviceWidth / 4, flexDirection: 'column', justifyContent: 'center' }}>
                        <Button
                            disabled={selectedPeriod === null || selectedPeriod === '----'}
                            block
                            onPress={() => {
                                console.log(selectedPeriod);
                                (selectedPeriod === null || selectedPeriod === '----') && makeSync();
                            }}
                        >
                            <Icon name='md-sync' />
                            <Text>Sincronizar con google sheets</Text>
                        </Button>
                    </View> */}

                    {/* <Form>
                        <Label><Text style={{ fontSize: 20 }}>Selecciona un periodo a sincronizar</Text></Label>
                        <Item>
                            <Picker
                                note
                                mode="dropdown"
                                style={{ width: Dimensions.get('window').width / 3 }}
                                selectedValue={selectedPeriod}
                                onValueChange={(value) => setSelectedPeriod(value)}
                            >
                                <Picker.Item label="----" value={null} />
                                {Periods}
                            </Picker>
                        </Item>
                    </Form> */}

                </Content>
                <Footer style={{ backgroundColor: '#fff00' }}>
                    <Button
                        disabled={selectedPeriod === null || selectedPeriod === '----'}
                        block
                        onPress={() => {
                            console.log(selectedPeriod);
                            makeSync();
                        }}
                    >
                        <Icon name='md-sync' />
                        <Text>Sincronizar con google sheets</Text>
                    </Button>
                </Footer>
            </Container>
        )
    }

    return (
        <Container>
            <Content>
                <Form>
                    <Label><Text style={{ fontSize: 20 }}>Selecciona un periodo a sincronizar</Text></Label>
                    <Item>
                        <Picker
                            note
                            mode="dropdown"
                            style={{ width: Dimensions.get('window').width - 20 }}
                            selectedValue={selectedPeriod}
                            onValueChange={(value) => setSelectedPeriod(value)}
                        >
                            <Picker.Item label="----" value={null} />
                            {Periods}
                        </Picker>
                    </Item>
                </Form>
            </Content>
            <Footer style={{ backgroundColor: '#fff00' }}>
                <Button
                    disabled={!selectedPeriod}
                    onPress={() => {
                        if (Platform.OS === 'web') {
                            makeSync()
                        } else {
                            Alert.alert('Atención', 'La sincronización es un proceso tardado que podria durar varios minutos', [
                                {
                                    text: 'OK', onPress: () => makeSync()
                                },
                                {
                                    text: 'Cancelar'
                                }
                            ],
                                {
                                    cancelable: false
                                })
                        }

                    }}
                >
                    <Icon name='md-sync' />
                    <Text>Sincronizar</Text>
                </Button>
            </Footer>
        </Container>
    )
};

export default SheetsScreen;
