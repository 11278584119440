import React, { useState, createContext, useEffect } from 'react';
import { AsyncStorage } from 'react-native';
import PropTypes from 'prop-types';
import Axios from 'axios';
import config from '../utils/config';
import PreLoader from '../components/PreLoader';
import Request from '../constants/Request';
import { loggedValues } from '../utils/enums';

export const CategoriesContext = createContext([]);
export const InfoContext = createContext([]);
export const BranchOfficesContext = createContext([]);
export const LoggContext = createContext<loggedValues>(loggedValues.NOLOGGED);
export const OrderingContext = createContext('');
export const UserContext = createContext({});
export const PriceListContext = createContext({});
export const ItemsContext = createContext([]);

async function getCategories(setCategories) {
  const params = {
    isActive: 'True',
    ordering: 'order',
    parent: Request.categories,
  };
  const uri = `${config.api.hostAnnalise}taxonomies`;
  await Axios.get(uri, {
    headers: config.api.headersPublic,
    params,
  })
    .then((response) => {
      setCategories(response.data);
    })
    .catch((error) => {
      console.log(error);
    });
}

async function getInfo(setInfo) {
  const params = {
    fields: 'title,link,excerpt,content,slug',
    isActive: 'True',
    ordering: 'createdAt',
    pageSize: 5,
    taxonomies: Request.info,
  };
  const uri = `${config.api.hostAnnalise}posts`;
  await Axios.get(uri, {
    headers: config.api.headersPublic,
    params,
  })
    .then((response) => {
      setInfo(response.data.results);
    })
    .catch((error) => {
      console.log(error);
    });
}

// Servicio al cliente en este caso
async function getBranchOffice(setBranchOffices) {
  const params = {
    fields: 'title,link,excerpt,content,slug',
    isActive: 'True',
    ordering: 'createdAt',
    pageSize: 5,
    taxonomies: Request.branchOffices,
  };
  const uri = `${config.api.hostAnnalise}posts`;
  await Axios.get(uri, {
    headers: config.api.headersPublic,
    params,
  })
    .then((response) => {
      setBranchOffices(response.data.results);
    })
    .catch((error) => {
      console.log(error);
    });
}

async function getPriceList(user, setPriceList) {
  const { id } = user;
  const uri = `${config.api.host}customer/user/${id}`;
  await Axios.get(uri, {
    headers: config.api.headers,
  })
    .then((response) => {
      Axios.get(`${config.api.host}item/price/lists`, {
        headers: config.api.headers,
        params: {
          fields: 'id,slug',
          customers: response.data.id,
        },
      })
        .then((res) => {
          if (res.data.length > 0) {
            config.api.priceList = true;
            setPriceList(res.data[0]);
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    })
    .catch((error) => {
      console.log(error);
    });
}

async function refreshToken(setLogged, setUser, setPriceList) {
  try {
    const appData = await AsyncStorage.getItem('appData');
    if (appData) {
      const parsed = JSON.parse(appData);
      const body = JSON.stringify({
        user: parsed.user.id,
        token: parsed.refresh_token,
      });
      await Axios.post(`${config.api.host}auth/refresh-token`, body, {
        headers: config.api.headers,
      })
        .then(async (response) => {
          try {
            // set access token and refresh token
            parsed.access_token = response.data.access_token;
            parsed.refresh_token = response.data.refresh_token;
            config.api.headers.Authorization = `Bearer ${response.data.access_token}`;
            setUser(parsed.user);
            getPriceList(parsed.user, setPriceList)
              .then(() => {
                setLogged(true);
              });
            await AsyncStorage.setItem('appData', JSON.stringify(parsed));
          } catch (error) {
            setLogged(false);
          }
        }).catch(() => {
          setLogged(false);
        });
    } else {
      setLogged(false);
      await true;
    }
  } catch (error) {
    setLogged(false);
    await true;
  }
}

async function getData(setItems) {
  try {
    const value = await AsyncStorage.getItem('items');
    if (value !== null) {
      // value previously stored
      setItems(JSON.parse(value));
    }
  } catch (e) {
    // error reading value
  }
}

function Store({ children }) {
  const [logged, setLogged] = useState<loggedValues>(loggedValues.NOLOGGED);
  const [working, setWorking] = useState(false);
  const [user, setUser] = useState({});
  const [items, setItems] = useState([]);
  const [priceList, setPriceList] = useState({});
  const [ordering, setOrdering] = useState('-createdAt');

  if (working) {
    return (
      <PreLoader />
    );
  }

  return (
    <LoggContext.Provider value={[logged, setLogged]} >
      {/** Provider suscribe a cambios del value a todos sus childrens,
        los children vuelven a renderizar cuando el value cambia* */}
      <UserContext.Provider value={[user, setUser]} >
        <PriceListContext.Provider value={[priceList, setPriceList]}>
          <ItemsContext.Provider value={[items, setItems]}>
            <OrderingContext.Provider value={[ordering, setOrdering]}>
              {children}
            </OrderingContext.Provider>
          </ItemsContext.Provider>
        </PriceListContext.Provider>
      </UserContext.Provider>
    </LoggContext.Provider>
  );
}
Store.propTypes = {
  children: PropTypes.element.isRequired,
};

export default Store;
