import Axios from "axios";
import config from "./config";
import { Platform } from "react-native";

const uploadImageAsync = async (file, url, reference, kind, employed, periodId) => {
    // const headers = config.api.headers;
    const headers = { ...config.api.headers, ...{ 'Content-Type': 'multipart/form-data' } };
    const endpoint = url; // Define Endpoint Here
    const payloadKey = 'attached_file'; // Define PayloadKey here Ex. 'file'
    const method = 'POST';
    let localUri = file.uri;
    let filename = localUri.split('/').pop();
    let name = file.name.split('.').pop();

    // Infer the type of the image
    let match = /\.(\w+)$/.exec(filename);
    let type = match ? `image/${match[1]}` : `image`;

    // Upload the image using the fetch and FormData APIs
    let formData = new FormData();
    formData.append(payloadKey, { uri: Platform.OS === "android" ? localUri : localUri.replace("file://", ""), name: filename, type });
    // formData.append(payloadKey, Platform.OS === "android" ? localUri : localUri.replace("file://", ""));
    formData.append('reference', reference);
    formData.append('name', `${reference}.${name}`);
    formData.append('employed', employed);
    formData.append('periodId', periodId);
    const body = {
        'attached_file': Platform.OS === "android" ? localUri : localUri.replace("file://", ""),
        'reference': reference,
        // 'name': file.name,
        'name': `${reference}.${name}`,
        'employed': employed,
        'periodId': periodId
    }
    const options = {
        headers,
    };
    return Axios.post(endpoint, formData, options);
};

const uploadImageAsync2 = async (uri, url, name, kind) => {
    const headers = { ...stores.loginStore.headers, ...{ 'Content-Type': 'multipart/form-data' } };
    const endpoint = url; // Define Endpoint Here
    const payloadKey = 'attached_file'; // Define PayloadKey here Ex. 'file'
    const method = 'POST';

    let localUri = uri;
    let filename = localUri.split('/').pop();

    // Infer the type of the image
    let match = /\.(\w+)$/.exec(filename);
    let type = match ? `image/${match[1]}` : `image`;

    // Upload the image using the fetch and FormData APIs
    let formData = new FormData();
    // Assume "photo" is the name of the form field the server expects
    formData.append(payloadKey, { uri: localUri, name: filename, type });
    // formData.append('kind', kind);
    formData.append('name', name);
    const options = {
        method,
        body: formData,
        headers,
    };
    return fetch(endpoint, options);
}

export default uploadImageAsync;