import * as React from 'react';
import * as Updates from 'expo-updates';
import { Alert } from 'react-native';


const reloadIfUpdateAvailable = async () => {
    try {
      const update = await Updates.checkForUpdateAsync();
      if (update.isAvailable) {
        Alert.alert(
          'Actualización',
          'Detectamos una actualizacion disponible para la aplicación',
          [
            {
              text: 'Actualizar',
              onPress: async () => {
                Alert.alert('', 'La app se reinciará automaticamente, por favor espera');
                await Updates.fetchUpdateAsync()
                  .then(() => {
                    Updates.reloadAsync();
                  });
              },
            },
          ],
          { cancelable: false },
        );
      }
    } catch (e) {
      console.log(e);
    }
  };

  export default reloadIfUpdateAvailable;