import * as React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import HomeScreen from '../screens/HomeScreen';
import CreateDeducibleExpence, { ModalScreen } from '../screens/expences/CreateDeducibleExpence';
import { navigationOptions } from '../constants/Navigation';
import LogoTitle from '../components/LogoTitle';
import SelectPeriodScreen from '../screens/expences/SelectPeriod';
import CreateNoDeducibleExpence from '../screens/expences/CreateNoDeducibleExpence';
import MovementsScreen from '../screens/movements/movementsScreen';

const Stack = createStackNavigator();

const HomeStack = ({ navigation, route }) => {
    return (
        <Stack.Navigator
            screenOptions={{ ...navigationOptions }}>
            <Stack.Screen name="HomeScreen"
                options={{
                    headerTitle: props => <LogoTitle {...props} />
                }}
                component={HomeScreen} />
            <Stack.Screen name="MovementsScreen"
                options={{
                    headerTitle: props => <LogoTitle {...props} title={'Movimientos'} />,
                }}
                component={MovementsScreen} />
            {/*<Stack.Screen name="CreateDeducibleExpence"
                options={{
                    headerTitle: props => <LogoTitle {...props} title={'Deducible'} />
                }}
                component={CreateDeducibleExpence} /> */}
        </Stack.Navigator>
    );
}


export default HomeStack;