
export const removeNulls = (obj) => {
  Object.keys(obj).forEach(function (key) {
    if (obj[key] === null || obj[key] === undefined) {
      delete obj[key];
    }
  });
  return obj;
}

export const hasNulls = (obj) => {
  const array = Object.values(obj);
  return array.includes(null);
}

export const validateNan = (number) => {
  return isNaN(number) ? 0 : parseFloat(number);
}

export const validateIntNan = (number) => {
  return isNaN(number) ? 0 : parseInt(number);
}