import React, { Component } from 'react';
import { Text, Icon, ListItem, Content, Thumbnail, Left, Body, Right, Badge, Button, Header, Container, Title } from 'native-base';
import styles from './style';

const logo = require('../../assets/images/icon2.png');

const SideBar = (props) => {
  const { navigation } = props;
  return (
    <Container>
      <Content style={{ backgroundColor: '#fff' }}>
        {/* <Thumbnail square style={styles.logo} circular source={logo} /> */}
        <ListItem
          button
          onPress={
            () => {
              navigation.navigate('CreateDeducibleExpence')
            }
          }
          icon style={styles.links}
        >
          <Left>
            <Icon active name="logo-usd" style={{ color: '#ffa726' }} />
          </Left>
          <Body style={{ borderColor: 'transparent' }}>
            <Text style={{ color: '#555' }}>Deducibles</Text>
          </Body>
        </ListItem>
        <ListItem
          button
          onPress={
            () => {
              navigation.navigate('CreateNoDeducibleExpence')
            }
          }
          icon style={styles.links}
        >
          <Left>
            <Icon active name="logo-usd" style={{ color: '#ffa726' }} />
            <Icon style={{
              color: '#fff',
              position: 'absolute',
              bottom: 5,
              right: 10,
              paddingTop: 0,
              paddingBottom: 3,
              paddingLeft: 6,
              paddingRight: 3,
              // paddingHorizontal: 2,
              fontSize: 18,
              height: 20,              
              backgroundColor: 'red',
              borderRadius: 100,
              zIndex: 99999,
              borderWidth: 1,
              borderColor: 'white',
              alignItems: 'center',
              justifyContent: 'center',
              alignSelf:'center'
            }} name="md-close" />
          </Left>
          <Body style={{ borderColor: 'transparent' }}>
            <Text style={{ color: '#555' }}>No deducibles</Text>
          </Body>
        </ListItem>
        {/* <ListItem
          button
          onPress={
            () => {
            }
          }
          icon style={styles.links}
        >
          <Left>
            <Icon active name="md-color-filter" style={{ color: '#555' }} />
          </Left>
          <Body style={{ borderColor: 'transparent' }}>
            <Text style={{ color: '#555' }}>Productos</Text>
          </Body>
        </ListItem>
        <ListItem
          button
          onPress={
            () => {
            }
          }
          icon style={styles.links}
        >
          <Left>
            <Icon active name="md-person" style={{ color: '#555' }} />
          </Left>
          <Body style={{ borderColor: 'transparent' }}>
            <Text style={{ color: '#555' }}>Clientes</Text>
          </Body>
        </ListItem>
        <ListItem
          button
          onPress={
            () => {
            }
          }
          icon style={styles.links}
        >
          <Left>
            <Icon active name="md-bus" style={{ color: '#555' }} />
          </Left>
          <Body style={{ borderColor: 'transparent' }}>
            <Text style={{ color: '#555' }}>Distribuidores</Text>
          </Body>
        </ListItem>
        <ListItem
          button
          onPress={
            () => {
            }
          }
          icon style={styles.links}
        >
          <Left>
            <Icon active name="md-git-compare" style={{ color: '#555' }} />
          </Left>
          <Body style={{ borderColor: 'transparent' }}>
            <Text style={{ color: '#555' }}>Sincronizar</Text>
          </Body>
          <Right>

          </Right>
        </ListItem>
        <ListItem
          button
          onLongPress={() => {
            Alert.alert('Info', `Versión ${Constants.manifest.version}
              Channel ${Constants.manifest.releaseChannel}
              `)
          }}
          onPress={
            () => {
            }
          }
          icon style={styles.links}
        >
          <Left>
            <Icon active name="md-settings" style={{ color: '#555' }} />
          </Left>
          <Body style={{ borderColor: 'transparent' }}>
            <Text style={{ color: '#555' }}>Configuración</Text>
          </Body>
        </ListItem>
        <ListItem
          button
          onPress={
            () => {
              db.transaction(
                (tx) => {
                  tx.executeSql('DELETE FROM Auth');
                }, () => {
                }, () => {
                });
            }
          }
          icon style={styles.links}
        >
          <Left>
            <Icon active name="md-log-out" style={{ color: '#555' }} />
          </Left>
          <Body style={{ borderColor: 'transparent' }}>
            <Text style={{ color: '#555' }}>Salir</Text>
          </Body>
        </ListItem> */}
      </Content>
    </Container>
  );
}

export default SideBar;
