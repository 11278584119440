import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import * as React from 'react';
import LinkingConfiguration from './LinkingConfiguration';
import BottomTabNavigator from './BottomTabNavigator';
import SignInScreen from '../screens/auth/SignInScreen';
import { LoggContext } from '../stores/Store';
import { navigationOptions } from '../constants/Navigation';
import { loggedValues } from '../utils/enums';
import BottomTabSheetsNavigator from './BottomTabSheetsNavigator';
import LogoTitle from '../components/LogoTitle';
import CreateNoDeducibleExpence from '../screens/expences/CreateNoDeducibleExpence';
import CreateDeducibleExpence from '../screens/expences/CreateDeducibleExpence';

const Stack = createStackNavigator();


const AppNavigator = () => {
  const [logged] = React.useContext(LoggContext);

  const renderStack = () => {
    if (logged === loggedValues.LOGGED) {
      return (
        <>
          <Stack.Screen name="Root" component={BottomTabNavigator} />
          <Stack.Screen name="CreateNoDeducibleExpence"
            options={{
              headerTitle: props => <LogoTitle {...props} title={'No Deducible'} />,
            }}
            component={CreateNoDeducibleExpence} />
          <Stack.Screen name="CreateDeducibleExpence"
            options={{
              headerTitle: props => <LogoTitle {...props} title={'Deducible'} />
            }}
            component={CreateDeducibleExpence} />
        </>
      )
    }
    if (logged === loggedValues.SHEETS) {
      return <Stack.Screen name="Sheets" component={BottomTabSheetsNavigator} />
    }
    return <Stack.Screen name="SingIn"
      component={SignInScreen}
      options={{
        headerShown: false
      }} />
  }
  return (
    <NavigationContainer linking={LinkingConfiguration}>
      <Stack.Navigator
        screenOptions={navigationOptions}
      >
        {
          renderStack()
        }

      </Stack.Navigator>
    </NavigationContainer>
  );
}

export default AppNavigator;