import * as React from 'react';
import { Platform, StatusBar, StyleSheet, View, AppState } from 'react-native';

import useCachedResources from './hooks/useCachedResources';
import AppNavigator from './navigation/AppNavigator';
import reloadIfUpdateAvailable from './hooks/reloadIfUpdate';
import Store from './stores/Store';


let appState = AppState.currentState;

export default function App(props) {
  const isLoadingComplete = useCachedResources();

  const _handleAppStateChange = (nextAppState) => {
    if (
      appState.match(/inactive|background/)
      && nextAppState === 'active'
    ) {
      reloadIfUpdateAvailable()
        .then(() => {
          appState = nextAppState;
        });
    }
  };

  AppState.addEventListener('change', _handleAppStateChange);

  if (!isLoadingComplete) {
    return null;
  } else {
    return (
      <View style={styles.container}>
        <StatusBar backgroundColor="#0B58A8" barStyle="light-content" />
        <Store>
          <AppNavigator />
        </Store>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
  },
});
