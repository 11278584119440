import * as FileSystem from 'expo-file-system';

const formValidation = {
	ApiUtils: {
		checkStatus(response) {
			// console.log(response)
			// if (response.status >= 200 && response.status < 300) {
			// 	return response;
			// }
			// const error = new Error(response);

			// throw error;
			if (!response.ok) {
				throw new Error(response);
			}
			return response;
		},
		parseParams(patch, params) {
			let queryParams = patch;
			Object.keys(params).map((key, index) => {
				if (index === 0) {
					queryParams += `?${key}=${params[key]}`;
				} else {
					queryParams += `&${key}=${params[key]}`;
				}
				return queryParams;
			});
			return queryParams;
		},
	}
};

export default formValidation;

const bytesToSize = (bytes, seperator = "") => {
	const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
	if (bytes == 0) return 'n/a'
	const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10)
	if (i === 0) return `${bytes}${seperator}${sizes[i]}`
	return `${(bytes / (1024 ** i)).toFixed(1)}${seperator}${sizes[i]}`
}

export const checkDiskAvailable =  async() => {
	return FileSystem.getFreeDiskStorageAsync()
		.then(async freeDiskStorage => {
			return bytesToSize(freeDiskStorage,' ')

			// Android: 17179869184
			// iOS: 17179869184
		});
}