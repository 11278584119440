import * as React from 'react';
import { Platform, StyleSheet, Alert, ToastAndroid, ProgressViewIOS, ProgressBarAndroid } from 'react-native';
import { Container, Content, Button, View, Text, Form, Item, Label, Input, Picker, Icon, Textarea, Header, Card, CardItem } from 'native-base';
import { Overlay } from 'react-native-elements';
import PreLoader from '../../components/PreLoader';
import { UserContext } from '../../stores/Store.tsx';
import Axios from 'axios';
import config from '../../utils/config';
import expencesKind from '../../constants/ExpenceKind';
import movements from '../../constants/movements';
import * as DocumentPicker from 'expo-document-picker';
import { removeNulls, hasNulls, validateNan } from '../../utils/checkNulls';
import DateTimePicker from '@react-native-community/datetimepicker';
import FormValidation from '../../utils/validation';
import getGuid from '../../utils/apiUtils';
import uploadImageAsync from '../../utils/uploadFile';
import * as FileSystem from 'expo-file-system';

export default function CreateNoDeducibleExpence(props) {
  const { navigation } = props;
  const [user] = React.useContext(UserContext);
  const [working, setWorking] = React.useState(false);
  const [visible, setVisible] = React.useState(false);
  const [visibleEmployee, setVisibleEmployee] = React.useState(false);
  const [mode, setMode] = React.useState('date');
  const [show, setShow] = React.useState(false);
  const [uploading, setUploading] = React.useState(false);
  const [uploadedFiles, setUploadedFiles] = React.useState({
    file: null,
    dataFile: null
  })
  const [period, setPeriod] = React.useState({
    "periodoId": "202006301",
    "nosemana": "6",
    "startdate": "2020-06-01",
    "endate": "2020-06-30",
    "contadorperiodo": 56
  });

  const [dataForm, setDataForm] = React.useState({
    "TipoMovimiento": null,
    "Comentario": null,
    "CuentaContable": '50101004',
    "CantidadComensales": 0,
    "kilometraje": 0,
    "Fecha": new Date(),
    "Total": null
  });


  React.useLayoutEffect(() => {
    setWorking(true);
    const date = new Date();
    const lastDayOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
    const ActualPeriod = `${date.getFullYear()}${date.getMonth() + 1 > 9 ? date.getMonth() + 1 : `0${date.getMonth() + 1}`}${lastDayOfMonth}1`;

    // const uri = `${config.api.host}periods/500`;
    const uri = `${config.api.host}periods/${ActualPeriod}`;
    Axios.get(uri, {
      headers: config.api.headers,
    })
      .then((response) => {
        setWorking(false);
        setPeriod(response.data);
      })
      .catch((error) => {
        console.log(error);
        setWorking(false);
        if (error.response && error.response.status === 404) {
          Alert.alert('Error', 'El periodo no es valido, contacta a tu administrador');
        } else {
          Alert.alert('Error', `${JSON.stringify(error.response)}`);
        }

      })
    // setWorking(false);
  }, []);

  const onChange = (event, selectedDate) => {
    const currentDate = selectedDate || dataForm.Fecha;
    setShow(Platform.OS === 'ios');
    setDataForm(prev => {
      return { ...prev, ...{ Fecha: currentDate } }
    })
  };

  const showMode = currentMode => {
    setShow(true);
    setMode(currentMode);
  };

  const showDatepicker = () => {
    showMode('date');
  };

  const formatDate = (date) => {
    let Fdate = new Date(date);

    let day = Fdate.getDate()
    let month = Fdate.getMonth() + 1
    let year = Fdate.getFullYear()

    if (month < 10) {
      return `${year}-0${month}-${day}`;
    } else {
      return `${year}-${month}-${day}`;
    }
  }

  const changeFileName = async (reference) => {
    const { dataFile } = uploadedFiles;
    let extension = dataFile.name.split('.').pop();
    let filename = dataFile.uri.split('/').pop();
    let newUri = dataFile.uri.replace(filename, `${reference}.${extension}`)
    console.log(reference);

    await FileSystem.copyAsync({
      from: dataFile.uri,
      to: newUri
    })
      .then(async () => {
        await FileSystem.getInfoAsync(newUri)
          .then(async (response) => {
            console.log(response);
            if (response.exists) {
              const nFile = uploadedFiles;
              nFile.dataFile.uri = newUri;
              await uploadFile(nFile);
            }

          })
          .catch((error) => {
            console.log('error', error);
            // Alert.alert('Error', `${JSON.stringify(error)}`);
          })

      })
      .catch((error) => {
        console.log('error', error);
        // Alert.alert('Error', `${JSON.stringify(error)}`);

      })
  }

  const saveNoDeducible = () => {
    setUploading(true);

    const data = {
      "uuid": `A-${getGuid()}-${user.noempleado}-${period.periodoId}`,
      "NumeroPeriodo": period.periodoId,
      "numeroEmpleado": user.noempleado,
      "fecha": formatDate(dataForm.Fecha),
      "estado": 0,
      "comentarios": dataForm.Comentario,
      "CantidadComensales": validateNan(dataForm.CantidadComensales),
      "kilometraje": validateNan(dataForm.kilometraje),
      "Aprobado2": 0,
      "Aprobado3": 0,
      "CentroCosto": user.centrocosto,
      "TipoMovimiento": dataForm.TipoMovimiento,
      "CuentaContable": dataForm.CuentaContable,
      "Mostrar": true,
      "kind": 1,
      "total": validateNan(dataForm.Total),
      "subTotal": validateNan(dataForm.SubTotal),
    }

    const cleanedData = removeNulls(data);

    const uriAttachments = `${config.api.host}attachments`;
    changeFileName(data.uuid)
      .then(() => {
        uploadImageAsync(uploadedFiles.dataFile, uriAttachments, data.uuid, 'editorImage', user.noempleado, period.periodoId)
          .then((response) => {
            cleanedData.PathFileName = response.data.url;
            console.log(cleanedData);

            setWorking(true);
            const uri = `${config.api.host}deductible`;
            Axios.post(uri, cleanedData, {
              headers: config.api.headers,
            })
              .then((response) => {
                Alert.alert(
                  'Registro correcto',
                  'No deducible registrado correctamente', [
                  {
                    text: 'OK',
                    onPress: () => navigation.pop()
                  }
                ]
                  ,
                  { cancelable: false });
                setWorking(false);
              })
              .catch((error) => {
                console.log(error.response);
                Alert.alert('Error', `${JSON.stringify(error.response.data)}`);
                setWorking(false);
              })
          })
          .catch((error) => {
            console.log(JSON.stringify(error));
            Alert.alert('Error', `${JSON.stringify(error.response.data)}`);
            setUploading(false);
          })
          .finally(() => {
            setUploading(false);
          });
      })
      .catch((error) => {
        console.log(JSON.stringify(error));
        Alert.alert('Error', `${JSON.stringify(error)}`);
        setUploading(false);
      })

  }

  const toggleOverlay = () => {
    setVisible(!visible);
  };

  const toggleOverlayEmployee = () => {
    setVisibleEmployee(!visibleEmployee);
  }

  const OverlayMenu = () => (
    <Card style={{ margin: 20, padding: 10 }} transparent>
      <CardItem header>
        <Label>Acciones</Label>
      </CardItem>
      <CardItem>
        <Button>
          <Icon name='md-cloud-upload' />
          <Text>CARGAR Evidencia</Text>
        </Button>
      </CardItem>
    </Card>
  )

  const OverlayEmployee = () => (
    <Card style={{ margin: 20, padding: 10 }} transparent >
      <CardItem header>
        <Label>Datos generales del usuario</Label>
      </CardItem>
      <CardItem>
        <Text>{`No. Empleado: ${user.noempleado}`}</Text>
      </CardItem>
      <CardItem>
        <Text>{`Nombre: ${user.nombre}`}</Text>
      </CardItem>
      <CardItem>
        <Text>{`Departamento: ${user.departamento}`}</Text>
      </CardItem>
      <CardItem>
        <Text>{`Centro de costo: ${user.centrocosto}`}</Text>
      </CardItem>
      <CardItem>
        <Text>{`Semana: ${period.nosemana}`}</Text>
      </CardItem>
    </Card>
  );


  const RenderMovements = () => {
    if (user.noempleado.includes('R')) {
      delete movements[2];
    } else {
      delete movements[0];
      delete movements[1];
    }
    return movements.map((element) => (<Picker.Item label={element.name} value={element.name} key={element.id} />))
  }

  const uploadFile = (file) => {
    setUploadedFiles(prev => { return { ...prev, ...{ file: true, dataFile: file } } });
  }

  const selectFile = (kind = null) => {
    const type = kind ? kind : '*/*';
    DocumentPicker.getDocumentAsync({
      type,

    })
      .then((result) => {

        if (result.type !== 'cancel') {
          uploadFile(result);
        }
      })
      .catch((error) => {
        Alert.alert('Ha ocurrido un error al seleccionar el archivo', JSON.stringify(error))
      })
  }

  const showToastWithGravity = (msg) => {
    ToastAndroid.showWithGravity(
      msg,
      ToastAndroid.SHORT,
      ToastAndroid.CENTER
    );
  };

  if (uploading) {
    return (
      <View style={{
        flex: 1,
        justifyContent: 'center',
        padding: 10,
        // alignItems: 'center'
      }}>
        <View style={{
          alignItems: 'center'
        }}>
          <Text>Subiendo archivos...</Text>
          <Text>Por favor espera a que la carga finalice</Text>
        </View>
        {
          Platform.OS === 'ios' ?
            <ProgressViewIOS progressTintColor="#2196F3" />
            : <ProgressBarAndroid styleAttr="Horizontal" color="#2196F3" />
        }

      </View>
    )
  }

  if (working) {
    return <PreLoader />;
  }

  const kinds = expencesKind.map((element) => (<Picker.Item label={element.name} value={element.id} key={element.id} />))

  return (
    <Container style={styles.container}>
      <Overlay isVisible={visible} onBackdropPress={toggleOverlay}>
        {OverlayMenu()}
      </Overlay>
      <Overlay isVisible={visibleEmployee} onBackdropPress={toggleOverlayEmployee}>
        {OverlayEmployee()}
      </Overlay>
      <Header style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }} transparent>
        <Button transparent bordered onPress={toggleOverlayEmployee}>
          <Icon style={{ color: '#000' }} name='md-person' />
          <Text style={{ color: '#000' }}>Usuario</Text>
        </Button>
        <Button
          disabled={hasNulls(dataForm)}
          style={{ backgroundColor: hasNulls(uploadedFiles) ? 'gray' : '#0B58A8' }}
          onPress={() => {
            if (hasNulls(uploadedFiles)) {
              const msg = `Primero debes cargar Evidencia`;
              showToastWithGravity(msg);

            } else {
              saveNoDeducible();
            }

          }}>
          <Icon name='md-save' />
          <Text >Guardar</Text>
        </Button>
      </Header>
      <Content padder>

        {show && (
          <DateTimePicker
            testID="dateTimePicker"
            value={dataForm.Fecha}
            mode={mode}
            is24Hour={true}
            display="default"
            onChange={onChange}
          />
        )}
        <Form>
          <Item>
            <Label>Fecha de emision: </Label>
            <Button transparent onPress={showDatepicker} >

              <Text>{formatDate(dataForm.Fecha)}</Text>
            </Button>
          </Item>
          <Item>
            <Label>Total $: </Label>
            <Input
              placeholder="Total"
              autoFocus
              keyboardType={"numeric"}
              defaultValue={0}
              value={dataForm.Total}
              onChangeText={(Total) => {
                setDataForm(prev => {
                  return { ...prev, ...{ Total } }
                })
              }} />
          </Item>
          <Item picker style={{ marginLeft: 15, marginRight: 15 }}>
            <Label>Movimiento: </Label>
            <Picker
              mode="dropdown"
              iosIcon={<Icon name="arrow-down" />}
              style={{ width: undefined, margin: 10 }}
              placeholder="Select your SIM"
              placeholderStyle={{ color: "#bfc6ea" }}
              placeholderIconColor="#007aff"
              selectedValue={dataForm.TipoMovimiento}
              onValueChange={(TipoMovimiento) => {
                setDataForm(prev => {
                  return { ...prev, ...{ TipoMovimiento } }
                })
              }}
            >
              <Picker.Item label='---' value={null} />
              {RenderMovements()}
            </Picker>
          </Item>
          {/* <Item picker style={{ marginLeft: 15, marginRight: 15 }}>
            <Label>Tipo de gasto: </Label>
            <Picker
              mode="dropdown"
              iosIcon={<Icon name="arrow-down" />}
              style={{ width: undefined, margin: 10 }}
              placeholder="Select your SIM"
              placeholderStyle={{ color: "#bfc6ea" }}
              placeholderIconColor="#007aff"
              selectedValue={dataForm.CuentaContable}
              onValueChange={(CuentaContable) => {
                setDataForm(prev => {
                  return { ...prev, ...{ CuentaContable } }
                })
              }}
            >
              <Picker.Item label='---' value={null} />
              {kinds}
            </Picker>
          </Item> */}
          {dataForm.CuentaContable === '50100104' ? <Item>
            <Label>Comensales: </Label>
            <Input
              placeholder="Comensales"
              keyboardType={"numeric"}
              defaultValue={0}
              value={dataForm.CantidadComensales}
              onChangeText={(CantidadComensales) => {
                setDataForm(prev => {
                  return { ...prev, ...{ CantidadComensales } }
                })
              }} />
          </Item> : null}
          {dataForm.CuentaContable === '50100151' ? <Item>
            <Label>kilometraje: </Label>
            <Input
              placeholder="kilometraje"
              keyboardType={"numeric"}
              defaultValue={0}
              value={dataForm.kilometraje}
              onChangeText={(kilometraje) => {
                setDataForm(prev => {
                  return { ...prev, ...{ kilometraje } }
                })
              }} />
          </Item> : null}
          <Textarea
            style={{ marginLeft: 15, marginRight: 15, marginTop: 15 }}
            rowSpan={5}
            bordered
            placeholder="Descripción"
            value={dataForm.Comentario}

            onChangeText={(Comentario) => {
              setDataForm(prev => {
                return { ...prev, ...{ Comentario } }
              })
            }}
          />

        </Form>
        <Card style={{ margin: 20, padding: 10, flexDirection: 'row', alignSelf: "center" }} transparent>
          <CardItem>
            <Button onPress={() => {
              selectFile();
            }}>
              <Icon name='md-cloud-upload' />
              <Text>CARGAR Evidencia</Text>
            </Button>
          </CardItem>
        </Card>
      </Content>
    </Container>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
  },
  developmentModeText: {
    marginBottom: 20,
    color: 'rgba(0,0,0,0.4)',
    fontSize: 14,
    lineHeight: 19,
    textAlign: 'center',
  },
  contentContainer: {
    paddingTop: 30,
  },
  welcomeContainer: {
    alignItems: 'center',
    marginTop: 10,
    marginBottom: 20,
  },
  welcomeImage: {
    width: 100,
    height: 80,
    resizeMode: 'contain',
    marginTop: 3,
    marginLeft: -10,
  },
  getStartedContainer: {
    alignItems: 'center',
    marginHorizontal: 50,
  },
  homeScreenFilename: {
    marginVertical: 7,
  },
  codeHighlightText: {
    color: 'rgba(96,100,109, 0.8)',
  },
  codeHighlightContainer: {
    backgroundColor: 'rgba(0,0,0,0.05)',
    borderRadius: 3,
    paddingHorizontal: 4,
  },
  getStartedText: {
    fontSize: 17,
    color: 'rgba(96,100,109, 1)',
    lineHeight: 24,
    textAlign: 'center',
  },
  tabBarInfoContainer: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    ...Platform.select({
      ios: {
        shadowColor: 'black',
        shadowOffset: { width: 0, height: -3 },
        shadowOpacity: 0.1,
        shadowRadius: 3,
      },
      android: {
        elevation: 20,
      },
    }),
    alignItems: 'center',
    backgroundColor: '#fbfbfb',
    paddingVertical: 20,
  },
  tabBarInfoText: {
    fontSize: 17,
    color: 'rgba(96,100,109, 1)',
    textAlign: 'center',
  },
  navigationFilename: {
    marginTop: 5,
  },
  helpContainer: {
    marginTop: 15,
    alignItems: 'center',
  },
  helpLink: {
    paddingVertical: 15,
  },
  helpLinkText: {
    fontSize: 14,
    color: '#2e78b7',
  },
});
