import React from 'react';
import {
  ActivityIndicator, View, StyleSheet, Dimensions, StatusBar,
} from 'react-native';

const { height } = Dimensions.get('window');
const styles = StyleSheet.create({
  preloader: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height,
    // backgroundColor: 'rgba(255,255,255,0.5)'
  },
});

const PreLoader = () => (
  <View style={[styles.preloader]}>
    <StatusBar backgroundColor="#fff" barStyle="dark-content" />
    <ActivityIndicator style={{ height: 80 }} size="large" />
  </View>
);

export default PreLoader;
