import React from 'react';
import { Icon } from 'native-base';

const leftIcon = (navigation, icon) => (
  <Icon
    name={icon}
    style={{ marginLeft: 20, color: '#fff' }}
    size={20}
    onPress={() => {
      if (navigation.current._root._open) {
        navigation.current._root.close();
      } else {
        navigation.current._root.open();
      }

    }}
  />
);

export default leftIcon;