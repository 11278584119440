import * as React from 'react';
import { ImageBackground, Platform, KeyboardAvoidingView, Keyboard, Alert } from 'react-native';
import { Container, Content, Text, Input, Button, Icon, Item, View, Spinner } from 'native-base';
import AsyncStorage from '@react-native-community/async-storage';
import styles from './styles';
import { LoggContext, UserContext } from '../../stores/Store';
import config from '../../utils/config';

import CryptoJS from "react-native-crypto-js";
import Axios from 'axios';
import { loggedValues } from '../../utils/enums';
import Colors from '../../constants/Colors';

const backgroundImage = require('../../assets/images/logo.png');
const SignInScreen = () => {

    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const [credentials, setCredentials] = React.useState({
        user: '',
        password: null,
        rememberMe: true
    });
    const [, setLogged] = React.useContext(LoggContext);
    const [, setUser] = React.useContext(UserContext);

    const getData = async () => {
        try {
            const value = await AsyncStorage.getItem('user');
            if (value !== null) {
                const data = JSON.parse(value);

                const bytes = CryptoJS.AES.decrypt(data.ciphertext.toString(), 'cirilo');
                const plaintext = bytes.toString(CryptoJS.enc.Utf8);
                const bytes2 = CryptoJS.AES.decrypt(data.ciphertext2.toString(), 'mantecas');
                const plaintext2 = bytes2.toString(CryptoJS.enc.Utf8);
                const body = {
                    user: plaintext,
                    password: plaintext2,
                };
                body.rememberMe = true;
                setCredentials(body);
            }
        } catch (e) {
            console.log(e);

            Alert.alert('Error', 'ha ocurrido un error al recuperar tu usuario');
        }
    };

    React.useEffect(() => {
        getData();
    }, []);

    const handleChange = (value) => {
        const data = { ...credentials, ...value };
        setCredentials(data);
    };

    const SignIn = () => {

        setIsSubmitting(true);
        const uri = `${config.api.host}login`;
        Axios.post(uri, credentials, {
            headers: config.api.headers,
        })
            .then(async (response) => {
                // console.log(response);

                if (credentials.rememberMe) {
                    const ciphertext = CryptoJS.AES.encrypt(credentials.user, 'cirilo');
                    const ciphertext2 = CryptoJS.AES.encrypt(credentials.password, 'mantecas');
                    const data = JSON.stringify({
                        ciphertext: ciphertext.toString(),
                        ciphertext2: ciphertext2.toString(),
                    });
                    await AsyncStorage.setItem('user', data);
                } else {
                    await AsyncStorage.removeItem('user');
                }

                await AsyncStorage.setItem('appData', JSON.stringify(response.data))
                    .then(() => {
                        config.api.headers.Authorization = `Bearer ${response.data.token}`;
                        setIsSubmitting(false);
                        console.log(response.data);
                        if (response.data.groups) {
                            const checkIsIntern = response.data.groups.filter((element) => element === 'interno')
                            setUser(response.data);
                            if (Platform.OS === 'web') {
                                if (checkIsIntern.length > 0) {
                                    setLogged(loggedValues.SHEETS);
                                }
                            } else {
                                if (checkIsIntern.length > 0) {
                                    setLogged(loggedValues.SHEETS);
                                }
                                else {
                                    setLogged(loggedValues.LOGGED);
                                }
                            }
                        } else {
                            setUser(response.data);
                            setLogged(loggedValues.LOGGED);
                        }

                    })
                    .catch((error) => {
                        console.log(error);
                        // setLogged(loggedValues.SHEETS);
                        setIsSubmitting(false);
                    });
            })
            .catch((error) => {
                console.log(error);
                // setLogged(loggedValues.SHEETS);
                if (Platform.OS == 'web') {
                    alert(`Ha ocurrido un error al iniciar sesión ${JSON.stringify(error)}`);
                } else {
                    Alert.alert('Ha ocurrido un error al iniciar sesión', JSON.stringify(error));
                }

                setIsSubmitting(false);
            });
    }

    if (Platform.OS == 'web') {
        return (
            <Container style={{ backgroundColor: '#0B58A8' }}>
                <Content contentContainerStyle={{ justifyContent: 'center', alignContent: 'center', flex: 1, flexDirection: 'row' }} style={{}}>
                    <ImageBackground square source={backgroundImage} style={styles.bgImageWeb}>
                        <View style={styles.bg}>
                            <Item underline style={{ marginBottom: 5, borderColor: '#fff', borderBottomWidth: (Platform.OS === 'ios') ? 1 : 1 }}>
                                <Icon active name="person" style={{ color: '#fff' }} />
                                <Input
                                    placeholder="Usuario"
                                    placeholderTextColor="#fff"
                                    keyboardType="default"
                                    autoCapitalize="none"
                                    autoCorrect={false}
                                    autoFocus
                                    style={{ color: '#fff' }}
                                    textContentType="username"
                                    value={credentials.user}
                                    onChangeText={value => handleChange({ user: value.length > 0 ? value : null })}
                                />
                            </Item>
                            <Item underline style={{ marginBottom: 5, marginTop: 15, borderColor: '#fff', borderBottomWidth: (Platform.OS === 'ios') ? 1 : 1 }}>
                                <Icon name="unlock" style={{ color: '#fff' }} />
                                <Input
                                    placeholder="Contraseña"
                                    placeholderTextColor="#fff"
                                    secureTextEntry
                                    style={{ color: '#fff' }}
                                    textContentType="password"
                                    value={credentials.password}
                                    onChangeText={value => handleChange({ password: value.length > 0 ? value : null })}
                                />
                            </Item>
                            <Button
                                rounded
                                block
                                style={styles.buttonLogin}
                                onPress={() => {
                                    SignIn();
                                }
                                }
                            >
                                {!isSubmitting ? <Text style={{ color: 'white' }}>Inciar sesión</Text> : <Spinner color={'rgba(231,76,60,1)'} />}
                            </Button>
                        </View>
                    </ImageBackground>
                </Content>
            </Container>
        );
    }

    return (
        <Container>
            <KeyboardAvoidingView
                // keyboardVerticalOffset={Platform.OS === 'ios' ? 40 : 40}
                behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
                style={styles.content}

            >
                <Content
                    style={styles.container}
                >
                    <ImageBackground square source={backgroundImage} style={styles.bgImage}>
                        <View style={styles.bg}>
                            <Item underline style={{ marginBottom: 5, borderColor: '#fff', borderBottomWidth: (Platform.OS === 'ios') ? 1 : 1 }}>
                                <Icon active name="person" style={{ color: '#fff' }} />
                                <Input
                                    placeholder="Usuario"
                                    placeholderTextColor="#fff"
                                    keyboardType="default"
                                    autoCapitalize="none"
                                    autoCorrect={false}
                                    style={{ color: '#fff' }}
                                    textContentType="username"
                                    value={credentials.user}
                                    onChangeText={value => handleChange({ user: value.length > 0 ? value : null })}
                                />
                            </Item>
                            <Item underline style={{ marginBottom: 5, marginTop: 15, borderColor: '#fff', borderBottomWidth: (Platform.OS === 'ios') ? 1 : 1 }}>
                                <Icon name="unlock" style={{ color: '#fff' }} />
                                <Input
                                    placeholder="Contraseña"
                                    placeholderTextColor="#fff"
                                    secureTextEntry
                                    style={{ color: '#fff' }}
                                    textContentType="password"
                                    value={credentials.password}
                                    onChangeText={value => handleChange({ password: value.length > 0 ? value : null })}
                                />
                            </Item>
                            <Button
                                rounded
                                block
                                style={styles.buttonLogin}
                                onPress={() => {
                                    SignIn();
                                }
                                }
                            >
                                {!isSubmitting ? <Text style={{ color: 'white' }}>Inciar sesión</Text> : <Spinner color={'rgba(231,76,60,1)'} />}
                            </Button>
                        </View>
                    </ImageBackground>
                </Content>
            </KeyboardAvoidingView>
        </Container>
    );
}

export default SignInScreen;