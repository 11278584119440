import * as React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { navigationOptions } from '../constants/Navigation';
import LogoTitle from '../components/LogoTitle';
import SheetsScreen from '../screens/sheets/SheetsScreen';

const Stack = createStackNavigator();

const SheetStack = ({ navigation, route }) => {
    return (
        <Stack.Navigator
            screenOptions={{ ...navigationOptions }}>
            <Stack.Screen name="SheetsScreen"
                options={{
                    headerTitle: props => <LogoTitle {...props} />
                }}
                component={SheetsScreen} />
        </Stack.Navigator>
    );
}


export default SheetStack;