import * as React from 'react';
import config from '../../utils/config';
import Axios from 'axios';
import { Alert, FlatList } from 'react-native';
import { Container, ListItem, Card, CardItem, Tabs, Tab, Header, Text, Left } from 'native-base';
import { SafeAreaView } from 'react-native-safe-area-context';
import PreLoader from '../../components/PreLoader';


var numeral = require('numeral');

const MovementsScreen = (props) => {
    const { route, navigation } = props;
    const { periodID } = route.params;
    const [working, setWorking] = React.useState(false);
    const [deductibles, setDeductibles] = React.useState([])
    const [noDeductibles, setNoDeductibles] = React.useState([])
    // const [deductibleParams, setDeductibleParams] = React.useState({

    // })


    const getDeducibles = () => {
        setWorking(true);
        const url = `${config.api.host}deductible`;
        Axios.get(url, {
            headers: config.api.headers,
            params: {
                NuperoPeriodo: periodID
            }
        })
            .then((response) => {
                setDeductibles(response.data.results);
                setWorking(false);
            })
            .catch((error) => {
                setWorking(false);
                Alert.alert('Error', JSON.stringify(error.response.data));
                console.log(error.response);

            })
    }

    React.useEffect(() => {
        getDeducibles();
    }, []);

    const getNoDeducibles = () => {
        setWorking(true);
        const url = `${config.api.host}deductible`;
        Axios.get(url, {
            headers: config.api.headers,
            params: {
                NuperoPeriodo: periodID,
                CuentaContable: 50101004
            }
        })
            .then((response) => {
                setNoDeductibles(response.data.results);
                setWorking(false);
            })
            .catch((error) => {
                setWorking(false);
                Alert.alert('Error', JSON.stringify(error.response.data));
                console.log(error.response);

            })
    }

    React.useEffect(() => {
        getNoDeducibles()
    }, []);

    const renderItem = (item) => (
        <Card>
            <CardItem>
                <Left />
                <Text>Fecha: {item.fecha}</Text>
            </CardItem>
            <CardItem>
                <Text>Razón Social: {item.RazonSocial}</Text>
            </CardItem>
            <CardItem>
                <Text>{`Tasa de impuesto: ${item.TasaImpuesto}`}</Text>
            </CardItem>
            <CardItem>
                <Text>{`Subtotal: ${numeral(item.subTotal).format('$0,00.00')}`}</Text>
            </CardItem>
            {item.Descuento ? <CardItem>
                <Text>{`Descuento: ${numeral(item.Descuento).format('$0,00.00')}`}</Text>
            </CardItem> : null}
            {item.iva ? <CardItem>
                <Text>{`IVA: ${numeral(item.iva).format('$0,00.00')}`}</Text>
            </CardItem> : null}
            {item.ISR ? <CardItem>
                <Text>{`Ret. ISR: ${numeral(item.RetISR).format('$0,00.00')}`}</Text>
            </CardItem> : null}
            {item.ish ? <CardItem>
                <Text>{`ISH: ${numeral(item.ish).format('$0,00.00')}`}</Text>
            </CardItem> : null}
            {item.RetIVA ? <CardItem>
                <Text>{`Ret. IVA: ${numeral(item.RetIVA).format('$0,00.00')}`}</Text>
            </CardItem> : null}
            {item.TUA ? <CardItem>
                <Text>{`TUA: ${numeral(item.TUA).format('$0,00.00')}`}</Text>
            </CardItem> : null}
            {item.totalImpuestosRetenidos ? <CardItem>
                <Text>{`Total Imp. Ret.: ${numeral(item.totalImpuestosRetenidos).format('$0,00.00')}`}</Text>
            </CardItem> : null}
            <CardItem>
                <Text>{`Total: ${numeral(item.total).format('$0,00.00')}`}</Text>
            </CardItem>
        </Card>
    )

    const renderListEmpy = () => {
        if (working) return null;
        return (
            <ListItem><Text>Sin información para mostrar</Text></ListItem>
        )
    }

    const Deducibles = () => (
        <SafeAreaView style={{ flex: 1, padding: 15 }}>
            <FlatList
                data={deductibles}
                renderItem={({ item }) => renderItem(item)}
                keyExtractor={(item) => `${item.uuid}`}
                ListEmptyComponent={renderListEmpy()}
            />
        </SafeAreaView>
    )

    const NoDeducibles = () => (
        <SafeAreaView style={{ flex: 1, padding: 15 }}>
            <FlatList
                data={noDeductibles}
                renderItem={(item) => renderItem(item.item)}
                keyExtractor={item => `${item.IdMovimiento}`}
            />
        </SafeAreaView>
    )

    if (working) return <PreLoader />

    return (
        <Container>
            {/* <Header hasTabs /> */}
            <Tabs
                locked
                tabBarBackgroundColor='#0B58A8'
            >
                <Tab heading="Deducibles">
                    {Deducibles()}
                </Tab>
                <Tab heading="No Deducibles">
                    {NoDeducibles()}
                </Tab>
            </Tabs>
        </Container>
    );
}

export default MovementsScreen;